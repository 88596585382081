.table {
  tr[onclick] {
    cursor: pointer;
  }
  tr.info td {
    color: white;
  }
  tr.index {
    font-weight: bold;
  }
}