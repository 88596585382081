$upload-bg: #eaedee !default;
$upload-area-bg: #d7dcdf !default;
$upload-area-width: 250px !default;
$upload-area-padding: 7px 7px 5px 7px !default;
$upload-area-font-size: 14px;
$upload-area-inner-height: 126px !default;
$upload-area-inner-bg: $upload-bg !default;
$upload-area-inner-padding: 30px 5px 10px 5px !default;
$upload-area-icon-size: 18px !default;

.clearfix {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  margin-top: 200px;

  @media only screen and (min-width: $screen-sm) {
    margin-top: initial;
  }
}

.upload {
  position: relative;
  background: $upload-bg no-repeat;
  background-position: center 70px;
  background-image: url('/assets/img/uploads-sm.png');

  @media only screen and (min-width: $screen-sm) {
    background: $upload-bg bottom center no-repeat;
    background-image: url('/assets/img/uploads-sm.png');
  }

  @media only screen and (min-width: $screen-md) {
    background-image: url('/assets/img/uploads.png');
  }

  h2 {
    margin-top: 0;
  }

  .description {
    position: relative;
    font-size: $upload-area-font-size+4px;
    font-style: italic;
    color: #a0a8ad;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 35px;


    @media only screen and (min-width: $screen-sm) {
      width: $upload-area-width - 10px;
    }

    @media only screen and (min-width: $screen-md) {
      width: $upload-area-width + 20px;
    }

    [class^="icon-"] {
      position: absolute;
      top: 4px;
      left: 0;
      font-size: 24px;
    }
  }

  .upload-col {
    &.first {
      flex: 1 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;

      @media only screen and (min-width: 615px) {
        margin-top: 20px;
        flex: 1 50%;
      }

      @media only screen and (min-width: $screen-sm) {
        margin-top: 20px;
        flex: 1;
        align-items: flex-start;
      }
    }

    &.last {
      flex: 1 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media only screen and (min-width: 615px) {
        margin-top: 20px;
        flex: 1 50%;
        justify-self: start;
      }

      @media only screen and (min-width: $screen-sm) {
        margin-top: 20px;
        flex: 1;
        align-items: flex-end;
      }
    }

    &.center {
      text-align: center;
      flex: 1 100%;

      .upload-area:last-child {
        margin: 0 auto 10px auto;
      }

      @media only screen and (min-width: $screen-sm) {
        display: inline-block;
        position: absolute;
        top: 80px;
        left: 50%;
        margin-left: -($upload-area-width - 60px)/2;
      }

      @media only screen and (min-width: $screen-md) {
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 50%;
        margin-left: -($upload-area-width - 60px)/2;
      }

      @media only screen and (min-width: $screen-md) {
        margin-left: -$upload-area-width/2;
      }
    }

    .upload-area {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed !important;
  }

  .upload-area {
    padding: $upload-area-padding;
    background-color: $upload-area-bg;
    border-radius: $border-radius-base;
    font-size: $upload-area-font-size;
    text-align: center;
    font-weight: normal;
    cursor: pointer;
    max-width: 243px;
    min-width: 214px;

    @media only screen and (min-width: $screen-sm) {
      display: block;
      width: $upload-area-width - 60px;
    }

    @media only screen and (min-width: $screen-md) {
      width: $upload-area-width;
    }

    .box-upload {
      position: relative;

      .inner {
        position: relative;
        background-color: $upload-area-inner-bg;
        padding: $upload-area-inner-padding;
        border-radius: $border-radius-base;
        max-width: 243px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 35px 35px 0 0;
          border-color: $brand-success transparent transparent transparent;
        }

        .corner {
          position: absolute;
          top: 0;
          left: 5px;
          color: #FFF;
          font-size: 18px;
          font-weight: lighter;
        }
      }
    }

    > p {
      margin-top: 5px;
      margin-bottom: 0;
      [class^="icon-"] {
        font-size: $upload-area-icon-size;
      }
    }

    img, input[type="file"], [data-upload="loading"] {
      display: none;
    }

    .upload-actions {
      display: none;
      margin-top: 5px;
      margin-bottom: 0;
      padding-left: 0;
      list-style-type: none;
      @include clearfix();

      li {
        display: block;
        float: left;
        width: 33.333333333%;
        font-size: $upload-area-icon-size;
      }

      @media only screen and (max-width: $screen-sm) {
        [data-upload="update"], [data-upload="edit"] {
            display: none !important;
          }
       }
    }

    &.loading {
      [data-upload="hide"], [data-upload="error"], .has-error {
        display: none;
      }

      [data-upload="loading"] {
        display: block;
      }
    }

    &.complete {
      .inner {
        padding: 0;
        min-height: 0;

        img {
          border-radius: $border-radius-base;
          display: block;
        }
      }

      .upload-icon, [data-upload="hide"], [data-upload="error"], [data-upload="loading"], .has-error, .upload-row {
        display: none;
      }

      .upload-actions {
        display: flex;
        justify-content: center;
      }

      @media only screen and (max-width: $screen-sm) {
        .upload-cell:before {
          font-family: "streamline", sans-serif;
          content: "\e1bf";
        }
      }
    }
  }

  .upload-row {
    @media only screen /*and (min-width: $screen-sm)*/ {
      display: table;
      width: 100%;
      height: 100px;

      .upload-cell {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}

[data-upload="draw-cover"] {
  border: 1px #8a6d3b solid;
  width: 750px;
  height: 432px;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto;
  display: block;
  cursor: crosshair;
}

.publish-photos-help-container {
  padding-bottom: 6px;

  .publish-photos-help {
    padding-top: 8px;
    margin: auto;
    width: 100%;
  }
  .question-mark-helper {
    margin: auto;
    text-align: center;
    text-decoration: underline;
    width: 20px;
    height: 20px;
    //background: no-repeat url('/assets/img/question-mark.png');
    //background-size: 20px;
    margin-bottom: 0;
  }
  .help {
    display: none;
    margin-top: 8px;
    margin-bottom: 3px;
    text-align: justify;
  }
  .btn-help {
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    &:hover {
      text-decoration: underline;
    }
  }
  .less {
    display: none;
  }
}
.icon-upload-photo {
  text-align: center;
  text-decoration: underline;
  width: 20px;
  height: 20px;
  background: no-repeat url('/assets/img/PlusPhoto.png');
  background-size: 20px;
  margin: 7px auto 0 auto;
}