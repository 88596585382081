$offer-bg: #eaedee;
$offer-bg-dark: #3E545F;

.header-offer {
  margin-top: 3px;

  svg {
    width: 20px;
    height: 20px;
    fill: $btn-default-bg;
  }

  span {
    display: inline-block;
    margin-right: 8px;
    position: relative;
    bottom: 3px;
    font-weight: 600;
    color: $btn-default-bg;
  }

  &.myoffer {
    svg {
      fill: $brand-success;
    }
  }
}

.offer.teaser {
  margin-bottom: 30px;

  &, a {
    color: $text-color;
    &:hover {
      text-decoration: none;
    }
  }
  padding: 10px;
  background-color: $offer-bg;

  > a {
    display: block;

  }

  .offer-title {
    font-weight: bold;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 20px;
    span.admin-abuse {
      font-size: 11px;
      font-weight: normal;
    }
  }

  .description {
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: lighter;
    font-size: 14px;
  }

  .img-cover {
    text-align: center;
    position: relative;

    .img-badge {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 7px;
      margin-bottom: 7px;
      z-index: 1;

      img {
        width: 24px;
        height: 24px;
      }
    }

    &.active-badge:after {
      position: absolute;
      right: 0;
      bottom: 0;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 50px 50px;
      border-color: transparent transparent $brand-success transparent;
    }

    &.active-badge.refused:after {
      position: absolute;
      right: 0;
      bottom: 0;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 50px 50px;
      border-color: transparent transparent $brand-danger transparent;
    }
  }

  .additionnals {
    @include clearfix;

    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 0;
    font-size: 14px;

    ul {
      @media only screen and (min-width: $screen-md) {
        float: left;
        width: 50%;
      }
      margin-left: 0;
      padding-left: 20px;
      font-weight: lighter;
      word-break: break-all;
      margin-bottom: 0;
    }
    #commission-list {
      width: 100%;
    }
  }

  .closing-date {
    margin-top: 20px;
    border-top: 1px solid #c6cdd0;
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .annot-wrapper {
    position: relative;
    min-height: 45px;

    .annot {
      float: none;
      margin-right: 120px;
      font-size: 12px;
    }

    .pull-right {
      position: absolute;
      top :0;
      right: 0;
    }
  }
  .annot {
    float: left;
    font-size: 14px;
    padding: 8px;
    line-height: 1;
    background-color: #FFF;
    border-radius: $border-radius-base;
    display: flex;
    align-items: center;
    justify-content: center;

    .number {
      font-size: 26px;
    }
  }

  a.btn-primary {
    color: #FFF;
  }

  &.dark {
    padding: 0;
    background-color: transparent;

    .offer-title {
      color: #FFF;
    }

    .offer-wrapper {
      padding: 10px;
      color: #FFF;
      background-color: $offer-bg-dark;
    }
  }
}

.col-sm-3 .offer.teaser, .col-sm-4 .offer.teaser {
  .img-cover {
    @media only screen and (min-width: $screen-sm) {
      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-height: 100%;
      }
    }
  }
}

.col-sm-3 .offer.teaser {
  .img-cover {

    @media only screen and (min-width: $screen-sm) {
      height: 82px;
    }

    @media only screen and (min-width: $screen-md) {
      height: 114px;
    }

    @media only screen and (min-width: $screen-lg) {
      height: 140px;
    }
  }
}

.col-sm-4 .offer.teaser {
  .img-cover {

    @media only screen and (min-width: $screen-sm) {
      height: 115px;
    }

    @media only screen and (min-width: $screen-md) {
      height: 155px;
    }

    @media only screen and (min-width: $screen-lg) {
      height: 195px;
    }
  }
}