.tooltip {
  font-size: 13px;
}

.help-tooltip {
  cursor: pointer;

  &.tooltip.top .tooltip-arrow {
    border-top-color: #FFFFFF;
  }
  &.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #FFFFFF;
  }
  &.tooltip.right .tooltip-arrow {
    border-right-color: #FFFFFF;
  }
  &.tooltip.left .tooltip-arrow {
    border-left-color: #FFFFFF;
  }
  .tooltip-inner {
    background-color: #FFFFFF;
    color: #000000;
  }
}

.offer-tooltip {
  cursor: pointer;

  &.tooltip.top .tooltip-arrow {
    border-top-color: $text-color;
  }
  &.tooltip.bottom .tooltip-arrow {
    border-bottom-color: $text-color;
  }
  &.tooltip.right .tooltip-arrow {
    border-right-color: $text-color;
  }
  &.tooltip.left .tooltip-arrow {
    border-left-color: $text-color;
  }
  .tooltip-inner {
    background-color: $text-color;
    color: #ffffff;
  }
}
