.offer {
  position: relative;
  .panel-body {
    .disabled {
      cursor: not-allowed;
      pointer-events: none;
      background-color: $btn-default-bg;
      color: $btn-default-color;
    }
  }

  .admin-edit-offer {
    font-size: 25px;
    padding: 6px 10px;
    background-color: #97be0d;
    color: #FFF;
    border-radius: 6px;
    float: right;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (min-width: $screen-sm) {
      padding-right: 130px;
    }
  }

  .date {
    font-weight: lighter;
    font-size: 24px;
    margin-bottom: 25px;

    @media screen and (max-width: $screen-sm) {
      padding-left: 25px;
    }

    @media screen and (min-width: $screen-sm) {
      padding-right: 130px;
    }
  }

  .img-container {
    img {
      border-radius: $border-radius-base;
    }

    .bxslider {
      padding-left: 0;

      > li {
        height: 435px;
        position: relative;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }

    .bxslider-cell {
      position: relative;
    }

    [data-control="detail-carousel"] {

      @media only screen and (min-width: $screen-sm) {
        height: 50px;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          max-height: 100%;
        }

      }

      @media only screen and (min-width: $screen-md) {
        height: 75px;
      }

      @media only screen and (min-width: $screen-lg) {
        height: 95px;
      }
    }

    .bxslider li, [data-control="detail-carousel"] {
      @include hidden();
      cursor: pointer;
    }

    .bxslider li {
      @media screen and (max-width: $screen-sm) {
        &.off {
          display: block;
        }
      }
    }
  }

  .closing-date {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: lighter;
    font-size: 20px;
  }

  .form-proposition {
    .form-row {
      @include clearfix();

      .form-col {
        @media screen and (min-width: $screen-sm) {
          float: left;
        }

        &.first, &.center {
          @media only screen and (min-width: $screen-sm) {
            width: 200px;
          }

          @media screen and (min-width: $screen-md) {
            width: 185px;
          }

          @media screen and (min-width: $screen-lg) {
            width: 255px;
          }
        }

        &.amount {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 18px;
          padding-top: 7px;
        }
      }
    }
  }

  .additionnals {
    font-size: 16px;
    margin-bottom: 30px;
    padding-left: 20px;
  }

  .tags {
    padding-left: 0;
    margin-bottom: 30px;

    li {
      position: relative;
      display: inline-block;
      font-size: 14px;
      padding: 5px 10px;
      border: 1px solid #85939a;
      border-left: transparent;
      color: #9aa2a7;
      border-radius: 0 $border-radius-base $border-radius-base 0;
      margin-left: 20px;
      margin-bottom: 5px;
      white-space: nowrap;

      span {
        display: block;

        &:before {
          content: "\25CB";
          display: block;
          position: absolute;
          left: -7px;
          top: 4px;
        }
      }

      &:before, &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        margin-top: -16px;
        left: -15px;
        border-style: solid;
        border-color: transparent #777 transparent transparent;
        border-width: 16px 15px 16px 0;
        border-radius: 1px;
        z-index: -1;
      }

      &:after {
        border-color: transparent #FFF transparent transparent;
        left: -14px;
      }
    }
  }

  .attached-document {
    padding-left: 0;

    li {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 20px;

      [class^="icon-"] {
        position: absolute;
        top: 50%;
        margin-top: -15px;
        left: 0;
        font-size: 22px;
      }

      p {
        font-size: 14px;
        margin-bottom: 0;
      }

      a {
        color: $text-color;
      }
    }
  }

  .comments {

    h3 {
      font-size: 18px;
      margin-bottom: 20px;
      text-align: center;
    }

    hr {
      margin-bottom: 20px;
      width: 240px;
      border-top-color: $text-color;
    }

    .comment {
      margin-bottom: 15px;
      font-size: 14px;
    }
  }
}

.newid-termsassu {
  border: 1px $grey-border solid;
  padding: 5px 15px;
  margin-bottom: 25px;

  ul {
    padding-left: 15px;
  }

  p {
    margin: 0;
  }
}
