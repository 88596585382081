$footer-text-color: #ADB6BB;

.footer {
  bottom: 0;
  left: 0;
  right: 0;

  background-color: $body-bg-dark;
  padding-top: $separator-space-top;
  padding-bottom: $separator-space-bottom;

  color: $footer-text-color;

  a {
    color: $footer-text-color;
  }

  .space-between {
    @media only screen and (min-width: $screen-sm) {
      @include flexbox();
      @include justify-content(space-between);
    }
  }

  .pre-links {
    @media only screen and (max-width: $screen-sm) {
      text-align: center;
    }

  }

  .links {
    padding-left: 0;

    @media only screen and (max-width: $screen-sm) {
      display: block;
      text-align: center;
    }

    li {
      display: inline-block;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      a {
        display: inline-block;
        position: relative;
        padding-left: 10px;

        &:before {
          content: "\25CF";
          display: block;
          position: absolute;
          left: -4px;
        }
      }

      @media only screen and (max-width: $screen-sm) {
        display: block;
        margin-top: 25px;
        margin-right: 0;
      }
    }
  }
}