.page-list {
  @media only screen and (max-width: $screen-sm) {
    .map, .map-header {
      display: none;
    }
  }
}

.bootstrap-select.dropdown .dropdown-menu li p {
  white-space: nowrap;
}

.bootstrap-select.dropdown .dropdown-menu li a {
  display: table-cell;
}