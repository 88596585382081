.page-user-account {
  .export-cover {
    .ibox {
      margin-right: 20px;
    }
  }
}

.block_email_list_for_validation_code {
  border: 1px solid $text-color;
  padding: 10px;
  margin-bottom: 15px;
  .block_start {
    display: flex;
    vertical-align: center;
    h3 {
      margin-top: 0;
    }
    span {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      i {
        font-size: 16px;
      }
      i:hover {
        cursor: help;
      }
      i:before {
        font-weight: 600;
        top: -4px;
      }
    }
  }
  ul {
    padding-left: 0;

    li {
      display: flex;
      justify-content: space-between;
      .form-group {
        width: 100%;
        .form-control {
          margin-bottom: 0px;
          width: 100%;
        }
      }
      .form-control {
        width: 96%;
        margin-bottom: 15px;
      }
      button {
        margin-left: 5px;
        span {
          font-size: 25px;
          font-weight: 500;
          position: relative;
          top: -5px;
        }
      }
    }
    button {
      font-size: 14px;
    }
    .button_add_email_validation_code {

    }
  }

}