.block-btn-captcha {
    display: flex;
    margin-bottom: 10px;
    .captcha_image {
        border: 1px solid $grey-border;
    }
    button{
        margin-right: 10px;
        padding: 0;
        .captcha_reload {
            .icon-sync-2 {
                color: white;
                margin: 0;
                font-size: 40px;
                line-height: 20px;
            }
        }
    }
}
