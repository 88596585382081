.input-group {
  .form-control {
    border-right-width: 0;
  }

  label.input-group-addon {
    font-size: 25px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}