select.form-control {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: #FFF url(/assets/img/dropdown.svg) no-repeat right 15px center;
  background-size: 17px 9px;
  padding-right: 35px;

  &::-ms-expand {
    display: none;
  }

  &:invalid {
    color: #b5bdc2;
    font-style: italic;
  }

  option[selected]:disabled {
    display: none;
  }
}