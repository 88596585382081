.wysiwyg {
  @import "ckeditor";
  &.faq{
    h1{
      margin-bottom: 30px;
    }
  }
}

a:not([href]) {
  color: inherit;
  text-decoration: none !important;
}
