@charset "UTF-8";

@font-face {
  font-family: "streamline";
  src:url("/assets/fonts/streamline/streamline.eot");
  src:url("/assets/fonts/streamline/streamline.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/streamline/streamline.woff") format("woff"),
  url("/assets/fonts/streamline/streamline.ttf") format("truetype"),
  url("/assets/fonts/streamline/streamline.svg#streamline-30px") format("svg");
  font-weight: normal;
  font-style: normal;

}

i[class^="icon-"] {
  vertical-align: middle;
}

[data-icon]:before {
  font-family: "streamline", sans-serif;
  content: attr(data-icon);
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: $font-family-icon;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-aim-1:before {
  content: "a";
}
.icon-aim-2:before {
  content: "b";
}
.icon-aim-3:before {
  content: "c";
}
.icon-bin-1:before {
  content: "d";
}
.icon-bin-2:before {
  content: "e";
}
.icon-binocular:before {
  content: "f";
}
.icon-bomb:before {
  content: "g";
}
.icon-clip-1:before {
  content: "h";
}
.icon-clip-2:before {
  content: "i";
}
.icon-cutter:before {
  content: "j";
}
.icon-delete-1:before {
  content: "k";
}
.icon-delete-2:before {
  content: "l";
}
.icon-edit-1:before {
  content: "m";
}
.icon-edit-2:before {
  content: "n";
}
.icon-edit-3:before {
  content: "o";
}
.icon-hide:before {
  content: "p";
}
.icon-ink:before {
  content: "q";
}
.icon-key-1:before {
  content: "r";
}
.icon-key-2:before {
  content: "s";
}
.icon-link-1:before {
  content: "t";
}
.icon-link-2:before {
  content: "u";
}
.icon-link-3:before {
  content: "v";
}
.icon-link-broken-1:before {
  content: "w";
}
.icon-link-broken-2:before {
  content: "x";
}
.icon-lock-1:before {
  content: "y";
}
.icon-lock-2:before {
  content: "z";
}
.icon-lock-3:before {
  content: "A";
}
.icon-lock-4:before {
  content: "B";
}
.icon-lock-5:before {
  content: "C";
}
.icon-lock-unlock-1:before {
  content: "D";
}
.icon-lock-unlock-2:before {
  content: "E";
}
.icon-magnifier:before {
  content: "F";
}
.icon-pen-1:before {
  content: "G";
}
.icon-pen-2:before {
  content: "H";
}
.icon-pen-3:before {
  content: "I";
}
.icon-pen-4:before {
  content: "J";
}
.icon-pencil-1:before {
  content: "K";
}
.icon-pencil-2:before {
  content: "L";
}
.icon-pencil-3:before {
  content: "M";
}
.icon-pin-1:before {
  content: "N";
}
.icon-pin-2:before {
  content: "O";
}
.icon-power-1:before {
  content: "P";
}
.icon-power-2:before {
  content: "Q";
}
.icon-preview-1:before {
  content: "R";
}
.icon-preview-2:before {
  content: "S";
}
.icon-scissor-1:before {
  content: "T";
}
.icon-scissor-2:before {
  content: "U";
}
.icon-skull-1:before {
  content: "V";
}
.icon-skull-2:before {
  content: "W";
}
.icon-type-1:before {
  content: "X";
}
.icon-type-2:before {
  content: "Y";
}
.icon-type-3:before {
  content: "Z";
}
.icon-type-4:before {
  content: "0";
}
.icon-zoom-area:before {
  content: "1";
}
.icon-zoom-in:before {
  content: "2";
}
.icon-zoom-out:before {
  content: "3";
}
.icon-cursor-1:before {
  content: "4";
}
.icon-cursor-2:before {
  content: "5";
}
.icon-cursor-3:before {
  content: "6";
}
.icon-cursor-6:before {
  content: "7";
}
.icon-cursor-move:before {
  content: "8";
}
.icon-cursor-select-area:before {
  content: "9";
}
.icon-cursors:before {
  content: "!";
}
.icon-hand:before {
  content: "\"";
}
.icon-hand-block:before {
  content: "#";
}
.icon-hand-grab-1:before {
  content: "$";
}
.icon-hand-grab-2:before {
  content: "%";
}
.icon-hand-point:before {
  content: "&";
}
.icon-hand-touch-1:before {
  content: "'";
}
.icon-hand-touch-2:before {
  content: "(";
}
.icon-hand-touch-3:before {
  content: ")";
}
.icon-hand-touch-4:before {
  content: "*";
}
.icon-bookmark-1:before {
  content: "+";
}
.icon-bookmark-2:before {
  content: ",";
}
.icon-bookmark-3:before {
  content: "-";
}
.icon-bookmark-4:before {
  content: ".";
}
.icon-tag-1:before {
  content: "/";
}
.icon-tag-2:before {
  content: ":";
}
.icon-tag-add:before {
  content: ";";
}
.icon-tag-delete:before {
  content: "<";
}
.icon-tags-1:before {
  content: "=";
}
.icon-tags-2:before {
  content: ">";
}
.icon-anchor-point-1:before {
  content: "?";
}
.icon-anchor-point-2:before {
  content: "@";
}
.icon-arrange-1:before {
  content: "[";
}
.icon-arrange-2:before {
  content: "]";
}
.icon-board:before {
  content: "^";
}
.icon-brush-1:before {
  content: "_";
}
.icon-brush-2:before {
  content: "`";
}
.icon-bucket:before {
  content: "{";
}
.icon-crop:before {
  content: "|";
}
.icon-dropper-1:before {
  content: "}";
}
.icon-dropper-2:before {
  content: "~";
}
.icon-dropper-3:before {
  content: "/\/";
}
.icon-glue:before {
  content: "\e000";
}
.icon-grid:before {
  content: "\e001";
}
.icon-layers:before {
  content: "\e002";
}
.icon-magic-wand-1:before {
  content: "\e003";
}
.icon-magic-wand-2:before {
  content: "\e004";
}
.icon-magnet:before {
  content: "\e005";
}
.icon-marker:before {
  content: "\e006";
}
.icon-palette:before {
  content: "\e007";
}
.icon-pen-5:before {
  content: "\e008";
}
.icon-pen-6:before {
  content: "\e009";
}
.icon-quill:before {
  content: "\e00a";
}
.icon-reflect:before {
  content: "\e00b";
}
.icon-roller:before {
  content: "\e00c";
}
.icon-ruler-1:before {
  content: "\e00d";
}
.icon-ruler-2:before {
  content: "\e00e";
}
.icon-scale-diagonal-1:before {
  content: "\e00f";
}
.icon-scale-diagonal-2:before {
  content: "\e010";
}
.icon-scale-horizontal:before {
  content: "\e011";
}
.icon-scale-tool-1:before {
  content: "\e012";
}
.icon-scale-tool-2:before {
  content: "\e013";
}
.icon-scale-tool-3:before {
  content: "\e014";
}
.icon-scale-vertical:before {
  content: "\e015";
}
.icon-shear-tool:before {
  content: "\e016";
}
.icon-spray:before {
  content: "\e017";
}
.icon-stamp:before {
  content: "\e018";
}
.icon-stationery-1:before {
  content: "\e019";
}
.icon-stationery-2:before {
  content: "\e01a";
}
.icon-stationery-3:before {
  content: "\e01b";
}
.icon-vector:before {
  content: "\e01c";
}
.icon-award-1:before {
  content: "\e01d";
}
.icon-award-2:before {
  content: "\e01e";
}
.icon-award-3:before {
  content: "\e01f";
}
.icon-award-4:before {
  content: "\e020";
}
.icon-award-5:before {
  content: "\e021";
}
.icon-award-6:before {
  content: "\e022";
}
.icon-crown-1:before {
  content: "\e023";
}
.icon-crown-2:before {
  content: "\e024";
}
.icon-crown-3:before {
  content: "\e025";
}
.icon-fire:before {
  content: "\e026";
}
.icon-flag-1:before {
  content: "\e027";
}
.icon-flag-2:before {
  content: "\e028";
}
.icon-flag-3:before {
  content: "\e029";
}
.icon-flag-4:before {
  content: "\e02a";
}
.icon-flag-5:before {
  content: "\e02b";
}
.icon-flag-6:before {
  content: "\e02c";
}
.icon-flag-7:before {
  content: "\e02d";
}
.icon-flag-8:before {
  content: "\e02e";
}
.icon-google-plus-1:before {
  content: "\e02f";
}
.icon-google-plus-2:before {
  content: "\e030";
}
.icon-hand-like-1:before {
  content: "\e031";
}
.icon-hand-like-2:before {
  content: "\e032";
}
.icon-hand-unlike-1:before {
  content: "\e033";
}
.icon-hand-unlike-2:before {
  content: "\e034";
}
.icon-heart-1:before {
  content: "\e035";
}
.icon-heart-2:before {
  content: "\e036";
}
.icon-heart-angel:before {
  content: "\e037";
}
.icon-heart-broken:before {
  content: "\e038";
}
.icon-heart-minus:before {
  content: "\e039";
}
.icon-heart-plus:before {
  content: "\e03a";
}
.icon-present:before {
  content: "\e03b";
}
.icon-rank-1:before {
  content: "\e03c";
}
.icon-rank-2:before {
  content: "\e03d";
}
.icon-ribbon:before {
  content: "\e03e";
}
.icon-star-1:before {
  content: "\e03f";
}
.icon-star-2:before {
  content: "\e040";
}
.icon-star-3:before {
  content: "\e041";
}
.icon-star-4:before {
  content: "\e042";
}
.icon-star-5:before {
  content: "\e043";
}
.icon-star-6:before {
  content: "\e044";
}
.icon-star-7:before {
  content: "\e045";
}
.icon-star-8:before {
  content: "\e046";
}
.icon-star-9:before {
  content: "\e047";
}
.icon-star-10:before {
  content: "\e048";
}
.icon-trophy:before {
  content: "\e049";
}
.icon-baloon:before {
  content: "\e04a";
}
.icon-bubble-1:before {
  content: "\e04b";
}
.icon-bubble-2:before {
  content: "\e04c";
}
.icon-bubble-add-1:before {
  content: "\e04d";
}
.icon-bubble-add-2:before {
  content: "\e04e";
}
.icon-bubble-add-3:before {
  content: "\e04f";
}
.icon-bubble-ask-1:before {
  content: "\e050";
}
.icon-bubble-ask-2:before {
  content: "\e051";
}
.icon-bubble-attention-2:before {
  content: "\e052";
}
.icon-bubble-attention-3:before {
  content: "\e053";
}
.icon-bubble-attention-4:before {
  content: "\e054";
}
.icon-bubble-attention-6:before {
  content: "\e055";
}
.icon-bubble-attention-7:before {
  content: "\e056";
}
.icon-bubble-block-1:before {
  content: "\e057";
}
.icon-bubble-block-2:before {
  content: "\e058";
}
.icon-bubble-block-3:before {
  content: "\e059";
}
.icon-bubble-chat-1:before {
  content: "\e05a";
}
.icon-bubble-chat-2:before {
  content: "\e05b";
}
.icon-bubble-check-1:before {
  content: "\e05c";
}
.icon-bubble-check-2:before {
  content: "\e05d";
}
.icon-bubble-check-3:before {
  content: "\e05e";
}
.icon-bubble-comment-1:before {
  content: "\e05f";
}
.icon-bubble-comment-2:before {
  content: "\e060";
}
.icon-bubble-conversation-1:before {
  content: "\e061";
}
.icon-bubble-conversation-2:before {
  content: "\e062";
}
.icon-bubble-conversation-3:before {
  content: "\e063";
}
.icon-bubble-conversation-4:before {
  content: "\e064";
}
.icon-bubble-conversation-5:before {
  content: "\e065";
}
.icon-bubble-conversation-6:before {
  content: "\e066";
}
.icon-bubble-delete-1:before {
  content: "\e067";
}
.icon-bubble-delete-2:before {
  content: "\e068";
}
.icon-bubble-delete-3:before {
  content: "\e069";
}
.icon-bubble-edit-1:before {
  content: "\e06a";
}
.icon-bubble-edit-2:before {
  content: "\e06b";
}
.icon-bubble-edit-3:before {
  content: "\e06c";
}
.icon-bubble-heart-1:before {
  content: "\e06d";
}
.icon-bubble-heart-2:before {
  content: "\e06e";
}
.icon-bubble-information:before {
  content: "\e06f";
}
.icon-bubble-information-1:before {
  content: "\e070";
}
.icon-bubble-minus-1:before {
  content: "\e071";
}
.icon-bubble-minus-2:before {
  content: "\e072";
}
.icon-bubble-minus-3:before {
  content: "\e073";
}
.icon-bubble-quote-1:before {
  content: "\e074";
}
.icon-bubble-quote-2:before {
  content: "\e075";
}
.icon-bubble-smiley-1:before {
  content: "\e076";
}
.icon-bubble-smiley-2:before {
  content: "\e077";
}
.icon-bubble-smiley-3:before {
  content: "\e078";
}
.icon-bubble-smiley-4:before {
  content: "\e079";
}
.icon-bubble-star-1:before {
  content: "\e07a";
}
.icon-bubble-star-2:before {
  content: "\e07b";
}
.icon-bubble-star-3:before {
  content: "\e07c";
}
.icon-chat-1:before {
  content: "\e07d";
}
.icon-chat-2:before {
  content: "\e07e";
}
.icon-chat-3:before {
  content: "\e07f";
}
.icon-chat-4:before {
  content: "\e080";
}
.icon-chat-5:before {
  content: "\e081";
}
.icon-chat-6:before {
  content: "\e082";
}
.icon-chat-7:before {
  content: "\e083";
}
.icon-smiley-happy-1:before {
  content: "\e084";
}
.icon-smiley-happy-2:before {
  content: "\e085";
}
.icon-smiley-happy-3:before {
  content: "\e086";
}
.icon-smiley-happy-4:before {
  content: "\e087";
}
.icon-smiley-happy-5:before {
  content: "\e088";
}
.icon-smiley-sad-1:before {
  content: "\e089";
}
.icon-smiley-surprise:before {
  content: "\e08a";
}
.icon-smiley-wink:before {
  content: "\e08b";
}
.icon-call-1:before {
  content: "\e08c";
}
.icon-call-2:before {
  content: "\e08d";
}
.icon-call-3:before {
  content: "\e08e";
}
.icon-call-4:before {
  content: "\e08f";
}
.icon-call-add:before {
  content: "\e090";
}
.icon-call-block:before {
  content: "\e091";
}
.icon-call-delete:before {
  content: "\e092";
}
.icon-call-in:before {
  content: "\e093";
}
.icon-call-minus:before {
  content: "\e094";
}
.icon-call-out:before {
  content: "\e095";
}
.icon-contact:before {
  content: "\e096";
}
.icon-fax:before {
  content: "\e097";
}
.icon-hang-up:before {
  content: "\e098";
}
.icon-message:before {
  content: "\e099";
}
.icon-mobile-phone-1:before {
  content: "\e09a";
}
.icon-mobile-phone-2:before {
  content: "\e09b";
}
.icon-phone-1:before {
  content: "\e09c";
}
.icon-phone-2:before {
  content: "\e09d";
}
.icon-phone-3:before {
  content: "\e09e";
}
.icon-phone-4:before {
  content: "\e09f";
}
.icon-phone-vibration:before {
  content: "\e0a0";
}
.icon-signal-fine:before {
  content: "\e0a1";
}
.icon-signal-full:before {
  content: "\e0a2";
}
.icon-signal-high:before {
  content: "\e0a3";
}
.icon-signal-no:before {
  content: "\e0a4";
}
.icon-signal-poor:before {
  content: "\e0a5";
}
.icon-signal-weak:before {
  content: "\e0a6";
}
.icon-smartphone:before {
  content: "\e0a7";
}
.icon-tape:before {
  content: "\e0a8";
}
.icon-camera-symbol-1:before {
  content: "\e0a9";
}
.icon-camera-symbol-2:before {
  content: "\e0aa";
}
.icon-camera-symbol-3:before {
  content: "\e0ab";
}
.icon-headphone:before {
  content: "\e0ac";
}
.icon-antenna-1:before {
  content: "\e0ad";
}
.icon-antenna-2:before {
  content: "\e0ae";
}
.icon-antenna-3:before {
  content: "\e0af";
}
.icon-hotspot-1:before {
  content: "\e0b0";
}
.icon-hotspot-2:before {
  content: "\e0b1";
}
.icon-link:before {
  content: "\e0b2";
}
.icon-megaphone-1:before {
  content: "\e0b3";
}
.icon-megaphone-2:before {
  content: "\e0b4";
}
.icon-radar:before {
  content: "\e0b5";
}
.icon-rss-1:before {
  content: "\e0b6";
}
.icon-rss-2:before {
  content: "\e0b7";
}
.icon-satellite:before {
  content: "\e0b8";
}
.icon-address-1:before {
  content: "\e0b9";
}
.icon-address-2:before {
  content: "\e0ba";
}
.icon-address-3:before {
  content: "\e0bb";
}
.icon-forward:before {
  content: "\e0bc";
}
.icon-inbox-1:before {
  content: "\e0bd";
}
.icon-inbox-2:before {
  content: "\e0be";
}
.icon-inbox-3:before {
  content: "\e0bf";
}
.icon-inbox-4:before {
  content: "\e0c0";
}
.icon-letter-1:before {
  content: "\e0c1";
}
.icon-letter-2:before {
  content: "\e0c2";
}
.icon-letter-3:before {
  content: "\e0c3";
}
.icon-letter-4:before {
  content: "\e0c4";
}
.icon-letter-5:before {
  content: "\e0c5";
}
.icon-mail-1:before {
  content: "\e0c6";
}
.icon-mail-2:before {
  content: "\e0c7";
}
.icon-mail-add:before {
  content: "\e0c8";
}
.icon-mail-attention:before {
  content: "\e0c9";
}
.icon-mail-block:before {
  content: "\e0ca";
}
.icon-mail-box-1:before {
  content: "\e0cb";
}
.icon-mail-box-2:before {
  content: "\e0cc";
}
.icon-mail-box-3:before {
  content: "\e0cd";
}
.icon-mail-checked:before {
  content: "\e0ce";
}
.icon-mail-compose:before {
  content: "\e0cf";
}
.icon-mail-delete:before {
  content: "\e0d0";
}
.icon-mail-favorite:before {
  content: "\e0d1";
}
.icon-mail-inbox:before {
  content: "\e0d2";
}
.icon-mail-lock:before {
  content: "\e0d3";
}
.icon-mail-minus:before {
  content: "\e0d4";
}
.icon-mail-read:before {
  content: "\e0d5";
}
.icon-mail-recieved-1:before {
  content: "\e0d6";
}
.icon-mail-recieved-2:before {
  content: "\e0d7";
}
.icon-mail-search-1:before {
  content: "\e0d8";
}
.icon-mail-search-2:before {
  content: "\e0d9";
}
.icon-mail-sent-1:before {
  content: "\e0da";
}
.icon-mail-sent-2:before {
  content: "\e0db";
}
.icon-mail-setting:before {
  content: "\e0dc";
}
.icon-mail-star:before {
  content: "\e0dd";
}
.icon-mail-sync:before {
  content: "\e0de";
}
.icon-mail-time:before {
  content: "\e0df";
}
.icon-outbox-1:before {
  content: "\e0e0";
}
.icon-outbox-2:before {
  content: "\e0e1";
}
.icon-plane-paper-1:before {
  content: "\e0e2";
}
.icon-plane-paper-2:before {
  content: "\e0e3";
}
.icon-reply-mail-1:before {
  content: "\e0e4";
}
.icon-reply-mail-2:before {
  content: "\e0e5";
}
.icon-connection-1:before {
  content: "\e0e6";
}
.icon-connection-2:before {
  content: "\e0e7";
}
.icon-connection-3:before {
  content: "\e0e8";
}
.icon-contacts-1:before {
  content: "\e0e9";
}
.icon-contacts-2:before {
  content: "\e0ea";
}
.icon-contacts-3:before {
  content: "\e0eb";
}
.icon-contacts-4:before {
  content: "\e0ec";
}
.icon-female:before {
  content: "\e0ed";
}
.icon-gender:before {
  content: "\e0ee";
}
.icon-gender-female:before {
  content: "\e0ef";
}
.icon-gender-male:before {
  content: "\e0f0";
}
.icon-id-1:before {
  content: "\e0f1";
}
.icon-id-2:before {
  content: "\e0f2";
}
.icon-id-3:before {
  content: "\e0f3";
}
.icon-id-4:before {
  content: "\e0f4";
}
.icon-id-5:before {
  content: "\e0f5";
}
.icon-id-6:before {
  content: "\e0f6";
}
.icon-id-7:before {
  content: "\e0f7";
}
.icon-id-8:before {
  content: "\e0f8";
}
.icon-male:before {
  content: "\e0f9";
}
.icon-profile-1:before {
  content: "\e0fa";
}
.icon-profile-2:before {
  content: "\e0fb";
}
.icon-profile-3:before {
  content: "\e0fc";
}
.icon-profile-4:before {
  content: "\e0fd";
}
.icon-profile-5:before {
  content: "\e0fe";
}
.icon-profile-6:before {
  content: "\e0ff";
}
.icon-profile-athlete:before {
  content: "\e100";
}
.icon-profile-bussiness-man:before {
  content: "\e101";
}
.icon-profile-cook:before {
  content: "\e102";
}
.icon-profile-cop:before {
  content: "\e103";
}
.icon-profile-doctor-1:before {
  content: "\e104";
}
.icon-profile-doctor-2:before {
  content: "\e105";
}
.icon-profile-gentleman-1:before {
  content: "\e106";
}
.icon-profile-gentleman-2:before {
  content: "\e107";
}
.icon-profile-graduate:before {
  content: "\e108";
}
.icon-profile-king:before {
  content: "\e109";
}
.icon-profile-lady-1:before {
  content: "\e10a";
}
.icon-profile-lady-2:before {
  content: "\e10b";
}
.icon-profile-man:before {
  content: "\e10c";
}
.icon-profile-nurse-1:before {
  content: "\e10d";
}
.icon-profile-nurse-2:before {
  content: "\e10e";
}
.icon-profile-prisoner:before {
  content: "\e10f";
}
.icon-profile-serviceman-1:before {
  content: "\e110";
}
.icon-profile-serviceman-2:before {
  content: "\e111";
}
.icon-profile-spy:before {
  content: "\e112";
}
.icon-profile-teacher:before {
  content: "\e113";
}
.icon-profile-thief:before {
  content: "\e114";
}
.icon-user-1:before {
  content: "\e115";
}
.icon-user-2:before {
  content: "\e116";
}
.icon-user-add-1:before {
  content: "\e117";
}
.icon-user-add-2:before {
  content: "\e118";
}
.icon-user-block-1:before {
  content: "\e119";
}
.icon-user-block-2:before {
  content: "\e11a";
}
.icon-user-checked-1:before {
  content: "\e11b";
}
.icon-user-checked-2:before {
  content: "\e11c";
}
.icon-user-delete-1:before {
  content: "\e11d";
}
.icon-user-delete-2:before {
  content: "\e11e";
}
.icon-user-edit-1:before {
  content: "\e11f";
}
.icon-user-edit-2:before {
  content: "\e120";
}
.icon-user-heart-1:before {
  content: "\e121";
}
.icon-user-heart-2:before {
  content: "\e122";
}
.icon-user-lock:before {
  content: "\e123";
}
.icon-user-lock-1:before {
  content: "\e124";
}
.icon-user-minus-1:before {
  content: "\e125";
}
.icon-user-minus-2:before {
  content: "\e126";
}
.icon-user-search-1:before {
  content: "\e127";
}
.icon-user-search-2:before {
  content: "\e128";
}
.icon-user-setting-1:before {
  content: "\e129";
}
.icon-user-setting-2:before {
  content: "\e12a";
}
.icon-user-star-1:before {
  content: "\e12b";
}
.icon-user-star-2:before {
  content: "\e12c";
}
.icon-basket-1:before {
  content: "\e12d";
}
.icon-basket-2:before {
  content: "\e12e";
}
.icon-basket-3:before {
  content: "\e12f";
}
.icon-basket-add:before {
  content: "\e130";
}
.icon-basket-minus:before {
  content: "\e131";
}
.icon-briefcase-2:before {
  content: "\e132";
}
.icon-cart-1:before {
  content: "\e133";
}
.icon-cart-2:before {
  content: "\e134";
}
.icon-cart-3:before {
  content: "\e135";
}
.icon-cart-4:before {
  content: "\e136";
}
.icon-cut:before {
  content: "\e137";
}
.icon-hand-bag-1:before {
  content: "\e138";
}
.icon-hand-bag-2:before {
  content: "\e139";
}
.icon-purse-1:before {
  content: "\e13a";
}
.icon-purse-2:before {
  content: "\e13b";
}
.icon-qr-code:before {
  content: "\e13c";
}
.icon-receipt-1:before {
  content: "\e13d";
}
.icon-receipt-2:before {
  content: "\e13e";
}
.icon-receipt-3:before {
  content: "\e13f";
}
.icon-receipt-4:before {
  content: "\e140";
}
.icon-shopping-1:before {
  content: "\e141";
}
.icon-shopping-bag-1:before {
  content: "\e142";
}
.icon-shopping-bag-2:before {
  content: "\e143";
}
.icon-shopping-bag-3:before {
  content: "\e144";
}
.icon-sign-new-1:before {
  content: "\e145";
}
.icon-sign-new-2:before {
  content: "\e146";
}
.icon-sign-park:before {
  content: "\e147";
}
.icon-sign-star:before {
  content: "\e148";
}
.icon-trolley-1:before {
  content: "\e149";
}
.icon-trolley-2:before {
  content: "\e14a";
}
.icon-trolley-3:before {
  content: "\e14b";
}
.icon-trolley-load:before {
  content: "\e14c";
}
.icon-trolley-off:before {
  content: "\e14d";
}
.icon-wallet-1:before {
  content: "\e14e";
}
.icon-wallet-2:before {
  content: "\e14f";
}
.icon-wallet-3:before {
  content: "\e150";
}
.icon-camera-1:before {
  content: "\e151";
}
.icon-camera-2:before {
  content: "\e152";
}
.icon-camera-3:before {
  content: "\e153";
}
.icon-camera-4:before {
  content: "\e154";
}
.icon-camera-5:before {
  content: "\e155";
}
.icon-camera-back:before {
  content: "\e156";
}
.icon-camera-focus:before {
  content: "\e157";
}
.icon-camera-frames:before {
  content: "\e158";
}
.icon-camera-front:before {
  content: "\e159";
}
.icon-camera-graph-1:before {
  content: "\e15a";
}
.icon-camera-graph-2:before {
  content: "\e15b";
}
.icon-camera-landscape:before {
  content: "\e15c";
}
.icon-camera-lens-1:before {
  content: "\e15d";
}
.icon-camera-lens-2:before {
  content: "\e15e";
}
.icon-camera-light:before {
  content: "\e15f";
}
.icon-camera-portrait:before {
  content: "\e160";
}
.icon-camera-view:before {
  content: "\e161";
}
.icon-film-1:before {
  content: "\e162";
}
.icon-film-2:before {
  content: "\e163";
}
.icon-photo-1:before {
  content: "\e164";
}
.icon-photo-2:before {
  content: "\e165";
}
.icon-photo-frame:before {
  content: "\e166";
}
.icon-photos-1:before {
  content: "\e167";
}
.icon-photos-2:before {
  content: "\e168";
}
.icon-polaroid:before {
  content: "\e169";
}
.icon-signal-camera-1:before {
  content: "\e16a";
}
.icon-signal-camera-2:before {
  content: "\e16b";
}
.icon-user-photo:before {
  content: "\e16c";
}
.icon-backward-1:before {
  content: "\e16d";
}
.icon-dvd-player:before {
  content: "\e16e";
}
.icon-eject-1:before {
  content: "\e16f";
}
.icon-film-3:before {
  content: "\e170";
}
.icon-forward-1:before {
  content: "\e171";
}
.icon-handy-cam:before {
  content: "\e172";
}
.icon-movie-play-1:before {
  content: "\e173";
}
.icon-movie-play-2:before {
  content: "\e174";
}
.icon-movie-play-3:before {
  content: "\e175";
}
.icon-next-1:before {
  content: "\e176";
}
.icon-pause-1:before {
  content: "\e177";
}
.icon-play-1:before {
  content: "\e178";
}
.icon-player:before {
  content: "\e179";
}
.icon-previous-1:before {
  content: "\e17a";
}
.icon-record-1:before {
  content: "\e17b";
}
.icon-slate:before {
  content: "\e17c";
}
.icon-stop-1:before {
  content: "\e17d";
}
.icon-television:before {
  content: "\e17e";
}
.icon-video-camera-1:before {
  content: "\e17f";
}
.icon-video-camera-2:before {
  content: "\e180";
}
.icon-backward-2:before {
  content: "\e181";
}
.icon-cd:before {
  content: "\e182";
}
.icon-eject-2:before {
  content: "\e183";
}
.icon-equalizer-1:before {
  content: "\e184";
}
.icon-equalizer-2:before {
  content: "\e185";
}
.icon-forward-2:before {
  content: "\e186";
}
.icon-gramophone:before {
  content: "\e187";
}
.icon-gramophone-record:before {
  content: "\e188";
}
.icon-guitar:before {
  content: "\e189";
}
.icon-headphone-1:before {
  content: "\e18a";
}
.icon-headphone-2:before {
  content: "\e18b";
}
.icon-microphone-1:before {
  content: "\e18c";
}
.icon-microphone-2:before {
  content: "\e18d";
}
.icon-microphone-3:before {
  content: "\e18e";
}
.icon-movie-play-4:before {
  content: "\e18f";
}
.icon-music-note-1:before {
  content: "\e190";
}
.icon-music-note-3:before {
  content: "\e191";
}
.icon-music-note-4:before {
  content: "\e192";
}
.icon-music-note-5:before {
  content: "\e193";
}
.icon-next-2:before {
  content: "\e194";
}
.icon-notes-1:before {
  content: "\e195";
}
.icon-notes-2:before {
  content: "\e196";
}
.icon-pause-2:before {
  content: "\e197";
}
.icon-piano:before {
  content: "\e198";
}
.icon-play-2:before {
  content: "\e199";
}
.icon-playlist:before {
  content: "\e19a";
}
.icon-previous-2:before {
  content: "\e19b";
}
.icon-radio-1:before {
  content: "\e19c";
}
.icon-radio-2:before {
  content: "\e19d";
}
.icon-record-2:before {
  content: "\e19e";
}
.icon-recorder:before {
  content: "\e19f";
}
.icon-saxophone:before {
  content: "\e1a0";
}
.icon-speaker-1:before {
  content: "\e1a1";
}
.icon-speaker-2:before {
  content: "\e1a2";
}
.icon-speaker-3:before {
  content: "\e1a3";
}
.icon-stop-2:before {
  content: "\e1a4";
}
.icon-tape-1:before {
  content: "\e1a5";
}
.icon-trumpet:before {
  content: "\e1a6";
}
.icon-volume-down-1:before {
  content: "\e1a7";
}
.icon-volume-down-2:before {
  content: "\e1a8";
}
.icon-volume-loud-1:before {
  content: "\e1a9";
}
.icon-volume-loud-2:before {
  content: "\e1aa";
}
.icon-volume-low-1:before {
  content: "\e1ab";
}
.icon-volume-low-2:before {
  content: "\e1ac";
}
.icon-volume-medium-1:before {
  content: "\e1ad";
}
.icon-volume-medium-2:before {
  content: "\e1ae";
}
.icon-volume-mute-1:before {
  content: "\e1af";
}
.icon-volume-mute-2:before {
  content: "\e1b0";
}
.icon-volume-mute-3:before {
  content: "\e1b1";
}
.icon-volume-up-1:before {
  content: "\e1b2";
}
.icon-volume-up-2:before {
  content: "\e1b3";
}
.icon-walkman:before {
  content: "\e1b4";
}
.icon-cloud:before {
  content: "\e1b5";
}
.icon-cloud-add:before {
  content: "\e1b6";
}
.icon-cloud-checked:before {
  content: "\e1b7";
}
.icon-cloud-delete:before {
  content: "\e1b8";
}
.icon-cloud-download:before {
  content: "\e1b9";
}
.icon-cloud-minus:before {
  content: "\e1ba";
}
.icon-cloud-refresh:before {
  content: "\e1bb";
}
.icon-cloud-sync:before {
  content: "\e1bc";
}
.icon-cloud-upload:before {
  content: "\e1bd";
}
.icon-download-1:before {
  content: "\e1be";
}
.icon-download-2:before {
  content: "\e1bf";
}
.icon-download-3:before {
  content: "\e1c0";
}
.icon-download-4:before {
  content: "\e1c1";
}
.icon-download-5:before {
  content: "\e1c2";
}
.icon-download-6:before {
  content: "\e1c3";
}
.icon-download-7:before {
  content: "\e1c4";
}
.icon-download-8:before {
  content: "\e1c5";
}
.icon-download-9:before {
  content: "\e1c6";
}
.icon-download-10:before {
  content: "\e1c7";
}
.icon-download-11:before {
  content: "\e1c8";
}
.icon-download-12:before {
  content: "\e1c9";
}
.icon-download-13:before {
  content: "\e1ca";
}
.icon-download-14:before {
  content: "\e1cb";
}
.icon-download-15:before {
  content: "\e1cc";
}
.icon-download-file:before {
  content: "\e1cd";
}
.icon-download-folder:before {
  content: "\e1ce";
}
.icon-goal-1:before {
  content: "\e1cf";
}
.icon-goal-2:before {
  content: "\e1d0";
}
.icon-transfer-1:before {
  content: "\e1d1";
}
.icon-transfer-2:before {
  content: "\e1d2";
}
.icon-transfer-3:before {
  content: "\e1d3";
}
.icon-transfer-4:before {
  content: "\e1d4";
}
.icon-transfer-5:before {
  content: "\e1d5";
}
.icon-transfer-6:before {
  content: "\e1d6";
}
.icon-transfer-7:before {
  content: "\e1d7";
}
.icon-transfer-8:before {
  content: "\e1d8";
}
.icon-transfer-9:before {
  content: "\e1d9";
}
.icon-transfer-10:before {
  content: "\e1da";
}
.icon-transfer-11:before {
  content: "\e1db";
}
.icon-transfer-12:before {
  content: "\e1dc";
}
.icon-upload-1:before {
  content: "\e1dd";
}
.icon-upload-2:before {
  content: "\e1de";
}
.icon-upload-3:before {
  content: "\e1df";
}
.icon-upload-4:before {
  content: "\e1e0";
}
.icon-upload-5:before {
  content: "\e1e1";
}
.icon-upload-6:before {
  content: "\e1e2";
}
.icon-upload-7:before {
  content: "\e1e3";
}
.icon-upload-8:before {
  content: "\e1e4";
}
.icon-upload-9:before {
  content: "\e1e5";
}
.icon-upload-10:before {
  content: "\e1e6";
}
.icon-upload-11:before {
  content: "\e1e7";
}
.icon-upload-12:before {
  content: "\e1e8";
}
.icon-clipboard-1:before {
  content: "\e1e9";
}
.icon-clipboard-2:before {
  content: "\e1ea";
}
.icon-clipboard-3:before {
  content: "\e1eb";
}
.icon-clipboard-add:before {
  content: "\e1ec";
}
.icon-clipboard-block:before {
  content: "\e1ed";
}
.icon-clipboard-checked:before {
  content: "\e1ee";
}
.icon-clipboard-delete:before {
  content: "\e1ef";
}
.icon-clipboard-edit:before {
  content: "\e1f0";
}
.icon-clipboard-minus:before {
  content: "\e1f1";
}
.icon-document-1:before {
  content: "\e1f2";
}
.icon-document-2:before {
  content: "\e1f3";
}
.icon-file-1:before {
  content: "\e1f4";
}
.icon-file-2:before {
  content: "\e1f5";
}
.icon-file-add:before {
  content: "\e1f6";
}
.icon-file-attention:before {
  content: "\e1f7";
}
.icon-file-block:before {
  content: "\e1f8";
}
.icon-file-bookmark:before {
  content: "\e1f9";
}
.icon-file-checked:before {
  content: "\e1fa";
}
.icon-file-code:before {
  content: "\e1fb";
}
.icon-file-delete:before {
  content: "\e1fc";
}
.icon-file-download:before {
  content: "\e1fd";
}
.icon-file-edit:before {
  content: "\e1fe";
}
.icon-file-favorite-1:before {
  content: "\e1ff";
}
.icon-file-favorite-2:before {
  content: "\e200";
}
.icon-file-graph-1:before {
  content: "\e201";
}
.icon-file-graph-2:before {
  content: "\e202";
}
.icon-file-home:before {
  content: "\e203";
}
.icon-file-image-1:before {
  content: "\e204";
}
.icon-file-image-2:before {
  content: "\e205";
}
.icon-file-list:before {
  content: "\e206";
}
.icon-file-lock:before {
  content: "\e207";
}
.icon-file-media:before {
  content: "\e208";
}
.icon-file-minus:before {
  content: "\e209";
}
.icon-file-music:before {
  content: "\e20a";
}
.icon-file-new:before {
  content: "\e20b";
}
.icon-file-registry:before {
  content: "\e20c";
}
.icon-file-search:before {
  content: "\e20d";
}
.icon-file-setting:before {
  content: "\e20e";
}
.icon-file-sync:before {
  content: "\e20f";
}
.icon-file-table:before {
  content: "\e210";
}
.icon-file-thumbnail:before {
  content: "\e211";
}
.icon-file-time:before {
  content: "\e212";
}
.icon-file-transfer:before {
  content: "\e213";
}
.icon-file-upload:before {
  content: "\e214";
}
.icon-file-zip:before {
  content: "\e215";
}
.icon-files-1:before {
  content: "\e216";
}
.icon-files-2:before {
  content: "\e217";
}
.icon-files-3:before {
  content: "\e218";
}
.icon-files-4:before {
  content: "\e219";
}
.icon-files-5:before {
  content: "\e21a";
}
.icon-files-6:before {
  content: "\e21b";
}
.icon-hand-file-1:before {
  content: "\e21c";
}
.icon-hand-file-2:before {
  content: "\e21d";
}
.icon-note-paper-1:before {
  content: "\e21e";
}
.icon-note-paper-2:before {
  content: "\e21f";
}
.icon-note-paper-add:before {
  content: "\e220";
}
.icon-note-paper-attention:before {
  content: "\e221";
}
.icon-note-paper-block:before {
  content: "\e222";
}
.icon-note-paper-checked:before {
  content: "\e223";
}
.icon-note-paper-delete:before {
  content: "\e224";
}
.icon-note-paper-download:before {
  content: "\e225";
}
.icon-note-paper-edit:before {
  content: "\e226";
}
.icon-note-paper-favorite:before {
  content: "\e227";
}
.icon-note-paper-lock:before {
  content: "\e228";
}
.icon-note-paper-minus:before {
  content: "\e229";
}
.icon-note-paper-search:before {
  content: "\e22a";
}
.icon-note-paper-sync:before {
  content: "\e22b";
}
.icon-note-paper-upload:before {
  content: "\e22c";
}
.icon-print:before {
  content: "\e22d";
}
.icon-folder-1:before {
  content: "\e22e";
}
.icon-folder-2:before {
  content: "\e22f";
}
.icon-folder-3:before {
  content: "\e230";
}
.icon-folder-4:before {
  content: "\e231";
}
.icon-folder-add:before {
  content: "\e232";
}
.icon-folder-attention:before {
  content: "\e233";
}
.icon-folder-block:before {
  content: "\e234";
}
.icon-folder-bookmark:before {
  content: "\e235";
}
.icon-folder-checked:before {
  content: "\e236";
}
.icon-folder-code:before {
  content: "\e237";
}
.icon-folder-delete:before {
  content: "\e238";
}
.icon-folder-download:before {
  content: "\e239";
}
.icon-folder-edit:before {
  content: "\e23a";
}
.icon-folder-favorite:before {
  content: "\e23b";
}
.icon-folder-home:before {
  content: "\e23c";
}
.icon-folder-image:before {
  content: "\e23d";
}
.icon-folder-lock:before {
  content: "\e23e";
}
.icon-folder-media:before {
  content: "\e23f";
}
.icon-folder-minus:before {
  content: "\e240";
}
.icon-folder-music:before {
  content: "\e241";
}
.icon-folder-new:before {
  content: "\e242";
}
.icon-folder-search:before {
  content: "\e243";
}
.icon-folder-setting:before {
  content: "\e244";
}
.icon-folder-share-1:before {
  content: "\e245";
}
.icon-folder-share-2:before {
  content: "\e246";
}
.icon-folder-sync:before {
  content: "\e247";
}
.icon-folder-transfer:before {
  content: "\e248";
}
.icon-folder-upload:before {
  content: "\e249";
}
.icon-folder-zip:before {
  content: "\e24a";
}
.icon-add-1:before {
  content: "\e24b";
}
.icon-add-2:before {
  content: "\e24c";
}
.icon-add-3:before {
  content: "\e24d";
}
.icon-add-4:before {
  content: "\e24e";
}
.icon-add-tag:before {
  content: "\e24f";
}
.icon-arrow-1:before {
  content: "\e250";
}
.icon-arrow-2:before {
  content: "\e251";
}
.icon-arrow-down-1:before {
  content: "\e252";
}
.icon-arrow-down-2:before {
  content: "\e253";
}
.icon-arrow-left-1:before {
  content: "\e254";
}
.icon-arrow-left-2:before {
  content: "\e255";
}
.icon-arrow-move-1:before {
  content: "\e256";
}
.icon-arrow-move-down:before {
  content: "\e257";
}
.icon-arrow-move-left:before {
  content: "\e258";
}
.icon-arrow-move-right:before {
  content: "\e259";
}
.icon-arrow-move-up:before {
  content: "\e25a";
}
.icon-arrow-right-1:before {
  content: "\e25b";
}
.icon-arrow-right-2:before {
  content: "\e25c";
}
.icon-arrow-up-1:before {
  content: "\e25d";
}
.icon-arrow-up-2:before {
  content: "\e25e";
}
.icon-back:before {
  content: "\e25f";
}
.icon-center-expand:before {
  content: "\e260";
}
.icon-center-reduce:before {
  content: "\e261";
}
.icon-delete-1-1:before {
  content: "\e262";
}
.icon-delete-2-1:before {
  content: "\e263";
}
.icon-delete-3:before {
  content: "\e264";
}
.icon-delete-4:before {
  content: "\e265";
}
.icon-delete-tag:before {
  content: "\e266";
}
.icon-expand-horizontal:before {
  content: "\e267";
}
.icon-expand-vertical:before {
  content: "\e268";
}
.icon-forward-3:before {
  content: "\e269";
}
.icon-infinity:before {
  content: "\e26a";
}
.icon-loading:before {
  content: "\e26b";
}
.icon-log-out-1:before {
  content: "\e26c";
}
.icon-loop-1:before {
  content: "\e26d";
}
.icon-loop-2:before {
  content: "\e26e";
}
.icon-loop-3:before {
  content: "\e26f";
}
.icon-minus-1:before {
  content: "\e270";
}
.icon-minus-2:before {
  content: "\e271";
}
.icon-minus-3:before {
  content: "\e272";
}
.icon-minus-4:before {
  content: "\e273";
}
.icon-minus-tag:before {
  content: "\e274";
}
.icon-move-diagonal-1:before {
  content: "\e275";
}
.icon-move-diagonal-2:before {
  content: "\e276";
}
.icon-move-horizontal-1:before {
  content: "\e277";
}
.icon-move-horizontal-2:before {
  content: "\e278";
}
.icon-move-vertical-1:before {
  content: "\e279";
}
.icon-move-vertical-2:before {
  content: "\e27a";
}
.icon-next-1-1:before {
  content: "\e27b";
}
.icon-next-2-1:before {
  content: "\e27c";
}
.icon-power-1-1:before {
  content: "\e27d";
}
.icon-power-2-1:before {
  content: "\e27e";
}
.icon-power-3:before {
  content: "\e27f";
}
.icon-power-4:before {
  content: "\e280";
}
.icon-power-5:before {
  content: "\e281";
}
.icon-recycle:before {
  content: "\e282";
}
.icon-refresh:before {
  content: "\e283";
}
.icon-repeat:before {
  content: "\e284";
}
.icon-return:before {
  content: "\e285";
}
.icon-scale-all-1:before {
  content: "\e286";
}
.icon-scale-center:before {
  content: "\e287";
}
.icon-scale-horizontal-1:before {
  content: "\e288";
}
.icon-scale-horizontal-2:before {
  content: "\e289";
}
.icon-scale-reduce-1:before {
  content: "\e28a";
}
.icon-scale-reduce-2:before {
  content: "\e28b";
}
.icon-scale-reduce-3:before {
  content: "\e28c";
}
.icon-scale-spread-1:before {
  content: "\e28d";
}
.icon-scale-spread-2:before {
  content: "\e28e";
}
.icon-scale-spread-3:before {
  content: "\e28f";
}
.icon-scale-vertical-1:before {
  content: "\e290";
}
.icon-scale-vertical-2:before {
  content: "\e291";
}
.icon-scroll-horizontal-1:before {
  content: "\e292";
}
.icon-scroll-horizontal-2:before {
  content: "\e293";
}
.icon-scroll-omnidirectional-1:before {
  content: "\e294";
}
.icon-scroll-omnidirectional-2:before {
  content: "\e295";
}
.icon-scroll-vertical-1:before {
  content: "\e296";
}
.icon-scroll-vertical-2:before {
  content: "\e297";
}
.icon-shuffle:before {
  content: "\e298";
}
.icon-split:before {
  content: "\e299";
}
.icon-sync-1:before {
  content: "\e29a";
}
.icon-sync-2:before {
  content: "\e29b";
}
.icon-timer:before {
  content: "\e29c";
}
.icon-transfer:before {
  content: "\e29d";
}
.icon-transfer-1-1:before {
  content: "\e29e";
}
.icon-chat-1-1:before {
  content: "\e29f";
}
.icon-chat-2-1:before {
  content: "\e2a0";
}
.icon-check-1:before {
  content: "\e2a1";
}
.icon-check-2:before {
  content: "\e2a2";
}
.icon-check-3:before {
  content: "\e2a3";
}
.icon-check-4:before {
  content: "\e2a4";
}
.icon-check-bubble:before {
  content: "\e2a5";
}
.icon-check-list:before {
  content: "\e2a6";
}
.icon-check-shield:before {
  content: "\e2a7";
}
.icon-cross-1:before {
  content: "\e2a8";
}
.icon-cross-bubble:before {
  content: "\e2a9";
}
.icon-cross-shield:before {
  content: "\e2aa";
}
.icon-briefcase:before {
  content: "\e2ab";
}
.icon-brightness-high:before {
  content: "\e2ac";
}
.icon-brightness-low:before {
  content: "\e2ad";
}
.icon-hammer-1:before {
  content: "\e2ae";
}
.icon-hammer-2:before {
  content: "\e2af";
}
.icon-pulse:before {
  content: "\e2b0";
}
.icon-scale:before {
  content: "\e2b1";
}
.icon-screw-driver:before {
  content: "\e2b2";
}
.icon-setting-adjustment:before {
  content: "\e2b3";
}
.icon-setting-gear:before {
  content: "\e2b4";
}
.icon-setting-gears-1:before {
  content: "\e2b5";
}
.icon-setting-gears-2:before {
  content: "\e2b6";
}
.icon-setting-wrenches:before {
  content: "\e2b7";
}
.icon-switch-1:before {
  content: "\e2b8";
}
.icon-switch-2:before {
  content: "\e2b9";
}
.icon-wrench:before {
  content: "\e2ba";
}
.icon-alarm-1:before {
  content: "\e2bb";
}
.icon-alarm-clock:before {
  content: "\e2bc";
}
.icon-alarm-no:before {
  content: "\e2bd";
}
.icon-alarm-snooze:before {
  content: "\e2be";
}
.icon-bell:before {
  content: "\e2bf";
}
.icon-calendar-1:before {
  content: "\e2c0";
}
.icon-calendar-2:before {
  content: "\e2c1";
}
.icon-clock-1:before {
  content: "\e2c2";
}
.icon-clock-2:before {
  content: "\e2c3";
}
.icon-clock-3:before {
  content: "\e2c4";
}
.icon-hourglass-1:before {
  content: "\e2c5";
}
.icon-hourglass-2:before {
  content: "\e2c6";
}
.icon-timer-1:before {
  content: "\e2c7";
}
.icon-timer-3-quarter-1:before {
  content: "\e2c8";
}
.icon-timer-3-quarter-2:before {
  content: "\e2c9";
}
.icon-timer-full-1:before {
  content: "\e2ca";
}
.icon-timer-full-2:before {
  content: "\e2cb";
}
.icon-timer-half-1:before {
  content: "\e2cc";
}
.icon-timer-half-2:before {
  content: "\e2cd";
}
.icon-timer-half-3:before {
  content: "\e2ce";
}
.icon-timer-half-4:before {
  content: "\e2cf";
}
.icon-timer-quarter-1:before {
  content: "\e2d0";
}
.icon-timer-quarter-2:before {
  content: "\e2d1";
}
.icon-watch-1:before {
  content: "\e2d2";
}
.icon-watch-2:before {
  content: "\e2d3";
}
.icon-alert-1:before {
  content: "\e2d4";
}
.icon-alert-2:before {
  content: "\e2d5";
}
.icon-alert-3:before {
  content: "\e2d6";
}
.icon-information:before {
  content: "\e2d7";
}
.icon-nuclear-1:before {
  content: "\e2d8";
}
.icon-nuclear-2:before {
  content: "\e2d9";
}
.icon-question-mark:before {
  content: "\e2da";
}
.icon-abacus:before {
  content: "\e2db";
}
.icon-amex-card:before {
  content: "\e2dc";
}
.icon-atm:before {
  content: "\e2dd";
}
.icon-balance:before {
  content: "\e2de";
}
.icon-bank-1:before {
  content: "\e2df";
}
.icon-bank-2:before {
  content: "\e2e0";
}
.icon-bank-note:before {
  content: "\e2e1";
}
.icon-bank-notes-1:before {
  content: "\e2e2";
}
.icon-bank-notes-2:before {
  content: "\e2e3";
}
.icon-bitcoins:before {
  content: "\e2e4";
}
.icon-board-1:before {
  content: "\e2e5";
}
.icon-box-1:before {
  content: "\e2e6";
}
.icon-box-2:before {
  content: "\e2e7";
}
.icon-box-3:before {
  content: "\e2e8";
}
.icon-box-download:before {
  content: "\e2e9";
}
.icon-box-shipping:before {
  content: "\e2ea";
}
.icon-box-upload:before {
  content: "\e2eb";
}
.icon-business-chart-1:before {
  content: "\e2ec";
}
.icon-business-chart-2:before {
  content: "\e2ed";
}
.icon-calculator-1:before {
  content: "\e2ee";
}
.icon-calculator-2:before {
  content: "\e2ef";
}
.icon-calculator-3:before {
  content: "\e2f0";
}
.icon-cash-register:before {
  content: "\e2f1";
}
.icon-chart-board:before {
  content: "\e2f2";
}
.icon-chart-down:before {
  content: "\e2f3";
}
.icon-chart-up:before {
  content: "\e2f4";
}
.icon-check:before {
  content: "\e2f5";
}
.icon-coins-1:before {
  content: "\e2f6";
}
.icon-coins-2:before {
  content: "\e2f7";
}
.icon-court:before {
  content: "\e2f8";
}
.icon-credit-card:before {
  content: "\e2f9";
}
.icon-credit-card-lock:before {
  content: "\e2fa";
}
.icon-delivery:before {
  content: "\e2fb";
}
.icon-dollar-bag:before {
  content: "\e2fc";
}
.icon-dollar-currency-1:before {
  content: "\e2fd";
}
.icon-dollar-currency-2:before {
  content: "\e2fe";
}
.icon-dollar-currency-3:before {
  content: "\e2ff";
}
.icon-dollar-currency-4:before {
  content: "\e300";
}
.icon-euro-bag:before {
  content: "\e301";
}
.icon-euro-currency-1:before {
  content: "\e302";
}
.icon-euro-currency-2:before {
  content: "\e303";
}
.icon-euro-currency-3:before {
  content: "\e304";
}
.icon-euro-currency-4:before {
  content: "\e305";
}
.icon-forklift:before {
  content: "\e306";
}
.icon-hand-card:before {
  content: "\e307";
}
.icon-hand-coin:before {
  content: "\e308";
}
.icon-keynote:before {
  content: "\e309";
}
.icon-master-card:before {
  content: "\e30a";
}
.icon-money:before {
  content: "\e30b";
}
.icon-parking-meter:before {
  content: "\e30c";
}
.icon-percent-1:before {
  content: "\e30d";
}
.icon-percent-2:before {
  content: "\e30e";
}
.icon-percent-3:before {
  content: "\e30f";
}
.icon-percent-4:before {
  content: "\e310";
}
.icon-percent-5:before {
  content: "\e311";
}
.icon-percent-up:before {
  content: "\e312";
}
.icon-pie-chart-1:before {
  content: "\e313";
}
.icon-pie-chart-2:before {
  content: "\e314";
}
.icon-piggy-bank:before {
  content: "\e315";
}
.icon-pound-currency-1:before {
  content: "\e316";
}
.icon-pound-currency-2:before {
  content: "\e317";
}
.icon-pound-currency-3:before {
  content: "\e318";
}
.icon-pound-currency-4:before {
  content: "\e319";
}
.icon-safe-1:before {
  content: "\e31a";
}
.icon-safe-2:before {
  content: "\e31b";
}
.icon-shop:before {
  content: "\e31c";
}
.icon-sign:before {
  content: "\e31d";
}
.icon-trolley:before {
  content: "\e31e";
}
.icon-truck-1:before {
  content: "\e31f";
}
.icon-truck-2:before {
  content: "\e320";
}
.icon-visa-card:before {
  content: "\e321";
}
.icon-yen-currency-1:before {
  content: "\e322";
}
.icon-yen-currency-2:before {
  content: "\e323";
}
.icon-yen-currency-3:before {
  content: "\e324";
}
.icon-yen-currency-4:before {
  content: "\e325";
}
.icon-add-marker-1:before {
  content: "\e326";
}
.icon-add-marker-2:before {
  content: "\e327";
}
.icon-add-marker-3:before {
  content: "\e328";
}
.icon-add-marker-4:before {
  content: "\e329";
}
.icon-add-marker-5:before {
  content: "\e32a";
}
.icon-compass-1:before {
  content: "\e32b";
}
.icon-compass-2:before {
  content: "\e32c";
}
.icon-compass-3:before {
  content: "\e32d";
}
.icon-delete-marker-1:before {
  content: "\e32e";
}
.icon-delete-marker-2:before {
  content: "\e32f";
}
.icon-delete-marker-3:before {
  content: "\e330";
}
.icon-delete-marker-4:before {
  content: "\e331";
}
.icon-delete-marker-5:before {
  content: "\e332";
}
.icon-favorite-marker:before {
  content: "\e333";
}
.icon-favorite-marker-1:before {
  content: "\e334";
}
.icon-favorite-marker-2:before {
  content: "\e335";
}
.icon-favorite-marker-3:before {
  content: "\e336";
}
.icon-globe:before {
  content: "\e337";
}
.icon-location:before {
  content: "\e338";
}
.icon-map-1:before {
  content: "\e339";
}
.icon-map-location:before {
  content: "\e33a";
}
.icon-map-marker-1:before {
  content: "\e33b";
}
.icon-map-marker-2:before {
  content: "\e33c";
}
.icon-map-marker-3:before {
  content: "\e33d";
}
.icon-map-marker-4:before {
  content: "\e33e";
}
.icon-map-pin:before {
  content: "\e33f";
}
.icon-map-pin-marker:before {
  content: "\e340";
}
.icon-marker-1:before {
  content: "\e341";
}
.icon-marker-2:before {
  content: "\e342";
}
.icon-marker-3:before {
  content: "\e343";
}
.icon-marker-4:before {
  content: "\e344";
}
.icon-minus-marker-1:before {
  content: "\e345";
}
.icon-minus-marker-2:before {
  content: "\e346";
}
.icon-minus-marker-3:before {
  content: "\e347";
}
.icon-minus-marker-4:before {
  content: "\e348";
}
.icon-pin-1-1:before {
  content: "\e349";
}
.icon-pin-2-1:before {
  content: "\e34a";
}
.icon-pin-location:before {
  content: "\e34b";
}
.icon-anchor:before {
  content: "\e34c";
}
.icon-bank:before {
  content: "\e34d";
}
.icon-beach:before {
  content: "\e34e";
}
.icon-boat:before {
  content: "\e34f";
}
.icon-building-1:before {
  content: "\e350";
}
.icon-building-2:before {
  content: "\e351";
}
.icon-building-3:before {
  content: "\e352";
}
.icon-buildings-1:before {
  content: "\e353";
}
.icon-buildings-2:before {
  content: "\e354";
}
.icon-buildings-3:before {
  content: "\e355";
}
.icon-buildings-4:before {
  content: "\e356";
}
.icon-castle:before {
  content: "\e357";
}
.icon-column:before {
  content: "\e358";
}
.icon-direction-sign:before {
  content: "\e359";
}
.icon-factory:before {
  content: "\e35a";
}
.icon-fence:before {
  content: "\e35b";
}
.icon-garage:before {
  content: "\e35c";
}
.icon-globe-1:before {
  content: "\e35d";
}
.icon-globe-2:before {
  content: "\e35e";
}
.icon-house-1:before {
  content: "\e35f";
}
.icon-house-2:before {
  content: "\e360";
}
.icon-house-3:before {
  content: "\e361";
}
.icon-house-4:before {
  content: "\e362";
}
.icon-library:before {
  content: "\e363";
}
.icon-light-house:before {
  content: "\e364";
}
.icon-pine-tree:before {
  content: "\e365";
}
.icon-pisa:before {
  content: "\e366";
}
.icon-skyscraper:before {
  content: "\e367";
}
.icon-temple:before {
  content: "\e368";
}
.icon-treasure-map:before {
  content: "\e369";
}
.icon-tree:before {
  content: "\e36a";
}
.icon-attention:before {
  content: "\e36b";
}
.icon-bug-1:before {
  content: "\e36c";
}
.icon-bug-2:before {
  content: "\e36d";
}
.icon-css3:before {
  content: "\e36e";
}
.icon-firewall:before {
  content: "\e36f";
}
.icon-html5:before {
  content: "\e370";
}
.icon-plugin-1:before {
  content: "\e371";
}
.icon-plugin-2:before {
  content: "\e372";
}
.icon-script:before {
  content: "\e373";
}
.icon-new-window:before {
  content: "\e374";
}
.icon-window-1:before {
  content: "\e375";
}
.icon-window-2:before {
  content: "\e376";
}
.icon-window-3:before {
  content: "\e377";
}
.icon-window-add:before {
  content: "\e378";
}
.icon-window-alert:before {
  content: "\e379";
}
.icon-window-check:before {
  content: "\e37a";
}
.icon-window-code-1:before {
  content: "\e37b";
}
.icon-window-code-2:before {
  content: "\e37c";
}
.icon-window-code-3:before {
  content: "\e37d";
}
.icon-window-column:before {
  content: "\e37e";
}
.icon-window-delete:before {
  content: "\e37f";
}
.icon-window-denied:before {
  content: "\e380";
}
.icon-window-download-1:before {
  content: "\e381";
}
.icon-window-download-2:before {
  content: "\e382";
}
.icon-window-edit:before {
  content: "\e383";
}
.icon-window-favorite-1:before {
  content: "\e384";
}
.icon-window-favorite-2:before {
  content: "\e385";
}
.icon-window-graph-1:before {
  content: "\e386";
}
.icon-window-graph-2:before {
  content: "\e387";
}
.icon-window-hand:before {
  content: "\e388";
}
.icon-window-home:before {
  content: "\e389";
}
.icon-window-list-1:before {
  content: "\e38a";
}
.icon-window-list-2:before {
  content: "\e38b";
}
.icon-window-lock:before {
  content: "\e38c";
}
.icon-window-minimize:before {
  content: "\e38d";
}
.icon-window-minus:before {
  content: "\e38e";
}
.icon-window-refresh:before {
  content: "\e38f";
}
.icon-window-registry:before {
  content: "\e390";
}
.icon-window-search:before {
  content: "\e391";
}
.icon-window-selection-1:before {
  content: "\e392";
}
.icon-window-selection-2:before {
  content: "\e393";
}
.icon-window-setting:before {
  content: "\e394";
}
.icon-window-sync:before {
  content: "\e395";
}
.icon-window-thumbnail-1:before {
  content: "\e396";
}
.icon-window-thumbnail-2:before {
  content: "\e397";
}
.icon-window-time:before {
  content: "\e398";
}
.icon-window-upload-1:before {
  content: "\e399";
}
.icon-window-upload-2:before {
  content: "\e39a";
}
.icon-database:before {
  content: "\e39b";
}
.icon-database-alert:before {
  content: "\e39c";
}
.icon-database-block:before {
  content: "\e39d";
}
.icon-database-check:before {
  content: "\e39e";
}
.icon-database-delete:before {
  content: "\e39f";
}
.icon-database-download:before {
  content: "\e3a0";
}
.icon-database-editor:before {
  content: "\e3a1";
}
.icon-database-lock:before {
  content: "\e3a2";
}
.icon-database-minus:before {
  content: "\e3a3";
}
.icon-database-network:before {
  content: "\e3a4";
}
.icon-database-plus:before {
  content: "\e3a5";
}
.icon-database-refresh:before {
  content: "\e3a6";
}
.icon-database-search:before {
  content: "\e3a7";
}
.icon-database-setting:before {
  content: "\e3a8";
}
.icon-database-sync:before {
  content: "\e3a9";
}
.icon-database-time:before {
  content: "\e3aa";
}
.icon-database-upload:before {
  content: "\e3ab";
}
.icon-battery-charging:before {
  content: "\e3ac";
}
.icon-battery-full:before {
  content: "\e3ad";
}
.icon-battery-high:before {
  content: "\e3ae";
}
.icon-battery-low:before {
  content: "\e3af";
}
.icon-battery-medium:before {
  content: "\e3b0";
}
.icon-cd-1:before {
  content: "\e3b1";
}
.icon-cd-2:before {
  content: "\e3b2";
}
.icon-chip:before {
  content: "\e3b3";
}
.icon-computer:before {
  content: "\e3b4";
}
.icon-disc:before {
  content: "\e3b5";
}
.icon-filter:before {
  content: "\e3b6";
}
.icon-floppy-disk:before {
  content: "\e3b7";
}
.icon-gameboy:before {
  content: "\e3b8";
}
.icon-harddisk-1:before {
  content: "\e3b9";
}
.icon-harddisk-2:before {
  content: "\e3ba";
}
.icon-imac:before {
  content: "\e3bb";
}
.icon-ipad-1:before {
  content: "\e3bc";
}
.icon-ipad-2:before {
  content: "\e3bd";
}
.icon-ipod:before {
  content: "\e3be";
}
.icon-joystick-1:before {
  content: "\e3bf";
}
.icon-joystick-2:before {
  content: "\e3c0";
}
.icon-joystick-3:before {
  content: "\e3c1";
}
.icon-keyboard-1:before {
  content: "\e3c2";
}
.icon-keyboard-2:before {
  content: "\e3c3";
}
.icon-kindle-1:before {
  content: "\e3c4";
}
.icon-kindle-2:before {
  content: "\e3c5";
}
.icon-laptop-1:before {
  content: "\e3c6";
}
.icon-laptop-2:before {
  content: "\e3c7";
}
.icon-memory-card:before {
  content: "\e3c8";
}
.icon-mobile-phone:before {
  content: "\e3c9";
}
.icon-mouse-1:before {
  content: "\e3ca";
}
.icon-mouse-2:before {
  content: "\e3cb";
}
.icon-mp3player:before {
  content: "\e3cc";
}
.icon-plug-1:before {
  content: "\e3cd";
}
.icon-plug-2:before {
  content: "\e3ce";
}
.icon-plug-slot:before {
  content: "\e3cf";
}
.icon-printer:before {
  content: "\e3d0";
}
.icon-projector:before {
  content: "\e3d1";
}
.icon-remote:before {
  content: "\e3d2";
}
.icon-router:before {
  content: "\e3d3";
}
.icon-screen-1:before {
  content: "\e3d4";
}
.icon-screen-2:before {
  content: "\e3d5";
}
.icon-screen-3:before {
  content: "\e3d6";
}
.icon-screen-4:before {
  content: "\e3d7";
}
.icon-smartphone-1:before {
  content: "\e3d8";
}
.icon-television-1:before {
  content: "\e3d9";
}
.icon-typewriter-1:before {
  content: "\e3da";
}
.icon-typewriter-2:before {
  content: "\e3db";
}
.icon-usb-1:before {
  content: "\e3dc";
}
.icon-usb-2:before {
  content: "\e3dd";
}
.icon-webcam:before {
  content: "\e3de";
}
.icon-wireless-router-1:before {
  content: "\e3df";
}
.icon-wireless-router-2:before {
  content: "\e3e0";
}
.icon-bluetooth:before {
  content: "\e3e1";
}
.icon-ethernet:before {
  content: "\e3e2";
}
.icon-ethernet-slot:before {
  content: "\e3e3";
}
.icon-firewire-1:before {
  content: "\e3e4";
}
.icon-firewire-2:before {
  content: "\e3e5";
}
.icon-network-1:before {
  content: "\e3e6";
}
.icon-network-2:before {
  content: "\e3e7";
}
.icon-server-1:before {
  content: "\e3e8";
}
.icon-server-2:before {
  content: "\e3e9";
}
.icon-server-3:before {
  content: "\e3ea";
}
.icon-usb:before {
  content: "\e3eb";
}
.icon-wireless-signal:before {
  content: "\e3ec";
}
.icon-book:before {
  content: "\e3ed";
}
.icon-book-1:before {
  content: "\e3ee";
}
.icon-book-2:before {
  content: "\e3ef";
}
.icon-book-3:before {
  content: "\e3f0";
}
.icon-book-4:before {
  content: "\e3f1";
}
.icon-book-5:before {
  content: "\e3f2";
}
.icon-book-6:before {
  content: "\e3f3";
}
.icon-book-7:before {
  content: "\e3f4";
}
.icon-book-download-1:before {
  content: "\e3f5";
}
.icon-book-download-2:before {
  content: "\e3f6";
}
.icon-book-favorite-1:before {
  content: "\e3f7";
}
.icon-bookmark-1-1:before {
  content: "\e3f8";
}
.icon-bookmark-2-1:before {
  content: "\e3f9";
}
.icon-bookmark-3-1:before {
  content: "\e3fa";
}
.icon-bookmark-4-1:before {
  content: "\e3fb";
}
.icon-books-1:before {
  content: "\e3fc";
}
.icon-books-2:before {
  content: "\e3fd";
}
.icon-books-3:before {
  content: "\e3fe";
}
.icon-briefcase-1:before {
  content: "\e3ff";
}
.icon-contact-book-1:before {
  content: "\e400";
}
.icon-contact-book-2:before {
  content: "\e401";
}
.icon-contact-book-3:before {
  content: "\e402";
}
.icon-contact-book-4:before {
  content: "\e403";
}
.icon-copyright:before {
  content: "\e404";
}
.icon-creative-commons:before {
  content: "\e405";
}
.icon-cube:before {
  content: "\e406";
}
.icon-data-filter:before {
  content: "\e407";
}
.icon-document-box-1:before {
  content: "\e408";
}
.icon-document-box-2:before {
  content: "\e409";
}
.icon-document-box-3:before {
  content: "\e40a";
}
.icon-drawer-1:before {
  content: "\e40b";
}
.icon-drawer-2:before {
  content: "\e40c";
}
.icon-drawer-3:before {
  content: "\e40d";
}
.icon-envelope:before {
  content: "\e40e";
}
.icon-favortie-book-2:before {
  content: "\e40f";
}
.icon-file:before {
  content: "\e410";
}
.icon-files:before {
  content: "\e411";
}
.icon-filter-1:before {
  content: "\e412";
}
.icon-filter-2:before {
  content: "\e413";
}
.icon-layers-1:before {
  content: "\e414";
}
.icon-list-1:before {
  content: "\e415";
}
.icon-list-2:before {
  content: "\e416";
}
.icon-newspaper-1:before {
  content: "\e417";
}
.icon-newspaper-2:before {
  content: "\e418";
}
.icon-registry-1:before {
  content: "\e419";
}
.icon-registry-2:before {
  content: "\e41a";
}
.icon-shield-1:before {
  content: "\e41b";
}
.icon-shield-2:before {
  content: "\e41c";
}
.icon-shield-3:before {
  content: "\e41d";
}
.icon-sketchbook:before {
  content: "\e41e";
}
.icon-sound-book:before {
  content: "\e41f";
}
.icon-thumbnails-1:before {
  content: "\e420";
}
.icon-thumbnails-2:before {
  content: "\e421";
}
.icon-hierarchy-1:before {
  content: "\e422";
}
.icon-hierarchy-2:before {
  content: "\e423";
}
.icon-hierarchy-3:before {
  content: "\e424";
}
.icon-hierarchy-4:before {
  content: "\e425";
}
.icon-hierarchy-5:before {
  content: "\e426";
}
.icon-hierarchy-6:before {
  content: "\e427";
}
.icon-hierarchy-7:before {
  content: "\e428";
}
.icon-hierarchy-8:before {
  content: "\e429";
}
.icon-network-1-1:before {
  content: "\e42a";
}
.icon-network-2-1:before {
  content: "\e42b";
}
.icon-backpack:before {
  content: "\e42c";
}
.icon-balance-1:before {
  content: "\e42d";
}
.icon-bed:before {
  content: "\e42e";
}
.icon-bench:before {
  content: "\e42f";
}
.icon-bomb-1:before {
  content: "\e430";
}
.icon-bricks:before {
  content: "\e431";
}
.icon-bullets:before {
  content: "\e432";
}
.icon-buoy-ring:before {
  content: "\e433";
}
.icon-campfire:before {
  content: "\e434";
}
.icon-can:before {
  content: "\e435";
}
.icon-candle:before {
  content: "\e436";
}
.icon-canon:before {
  content: "\e437";
}
.icon-cctv-1:before {
  content: "\e438";
}
.icon-cctv-2:before {
  content: "\e439";
}
.icon-chair:before {
  content: "\e43a";
}
.icon-chair-director:before {
  content: "\e43b";
}
.icon-cigarette:before {
  content: "\e43c";
}
.icon-construction-sign:before {
  content: "\e43d";
}
.icon-diamond:before {
  content: "\e43e";
}
.icon-disabled:before {
  content: "\e43f";
}
.icon-door:before {
  content: "\e440";
}
.icon-drawer:before {
  content: "\e441";
}
.icon-driller:before {
  content: "\e442";
}
.icon-dumbbell:before {
  content: "\e443";
}
.icon-fire-extinguisher:before {
  content: "\e444";
}
.icon-flashlight:before {
  content: "\e445";
}
.icon-gas-station:before {
  content: "\e446";
}
.icon-gun:before {
  content: "\e447";
}
.icon-lamp-1:before {
  content: "\e448";
}
.icon-lamp-2:before {
  content: "\e449";
}
.icon-lamp-3:before {
  content: "\e44a";
}
.icon-lamp-4:before {
  content: "\e44b";
}
.icon-lightbulb-1:before {
  content: "\e44c";
}
.icon-lightbulb-2:before {
  content: "\e44d";
}
.icon-measuring-tape:before {
  content: "\e44e";
}
.icon-mine-cart:before {
  content: "\e44f";
}
.icon-missile:before {
  content: "\e450";
}
.icon-ring:before {
  content: "\e451";
}
.icon-scale-1:before {
  content: "\e452";
}
.icon-shovel:before {
  content: "\e453";
}
.icon-smoke-no:before {
  content: "\e454";
}
.icon-sofa-1:before {
  content: "\e455";
}
.icon-sofa-2:before {
  content: "\e456";
}
.icon-sofa-3:before {
  content: "\e457";
}
.icon-target:before {
  content: "\e458";
}
.icon-torch:before {
  content: "\e459";
}
.icon-traffic-cone:before {
  content: "\e45a";
}
.icon-traffic-light-1:before {
  content: "\e45b";
}
.icon-traffic-light-2:before {
  content: "\e45c";
}
.icon-treasure-1:before {
  content: "\e45d";
}
.icon-treasure-2:before {
  content: "\e45e";
}
.icon-trowel:before {
  content: "\e45f";
}
.icon-watering-can:before {
  content: "\e460";
}
.icon-weigh:before {
  content: "\e461";
}
.icon-academic-cap:before {
  content: "\e462";
}
.icon-baseball-helmet:before {
  content: "\e463";
}
.icon-beanie:before {
  content: "\e464";
}
.icon-bike-helmet:before {
  content: "\e465";
}
.icon-bow:before {
  content: "\e466";
}
.icon-cap:before {
  content: "\e467";
}
.icon-chaplin:before {
  content: "\e468";
}
.icon-chef-hat:before {
  content: "\e469";
}
.icon-cloth-hanger:before {
  content: "\e46a";
}
.icon-fins:before {
  content: "\e46b";
}
.icon-football-helmet:before {
  content: "\e46c";
}
.icon-glasses:before {
  content: "\e46d";
}
.icon-glasses-1:before {
  content: "\e46e";
}
.icon-glasses-2:before {
  content: "\e46f";
}
.icon-magician-hat:before {
  content: "\e470";
}
.icon-monocle-1:before {
  content: "\e471";
}
.icon-monocle-2:before {
  content: "\e472";
}
.icon-necktie:before {
  content: "\e473";
}
.icon-polo-shirt:before {
  content: "\e474";
}
.icon-safety-helmet:before {
  content: "\e475";
}
.icon-scuba-tank:before {
  content: "\e476";
}
.icon-shirt-1:before {
  content: "\e477";
}
.icon-shirt-2:before {
  content: "\e478";
}
.icon-sneakers:before {
  content: "\e479";
}
.icon-snorkel:before {
  content: "\e47a";
}
.icon-sombrero:before {
  content: "\e47b";
}
.icon-sunglasses:before {
  content: "\e47c";
}
.icon-tall-hat:before {
  content: "\e47d";
}
.icon-trousers:before {
  content: "\e47e";
}
.icon-walking-stick:before {
  content: "\e47f";
}
.icon-arrow-redo:before {
  content: "\e480";
}
.icon-arrow-undo:before {
  content: "\e481";
}
.icon-bold:before {
  content: "\e482";
}
.icon-columns:before {
  content: "\e483";
}
.icon-eraser:before {
  content: "\e484";
}
.icon-font-color:before {
  content: "\e485";
}
.icon-html:before {
  content: "\e486";
}
.icon-italic:before {
  content: "\e487";
}
.icon-list-1-1:before {
  content: "\e488";
}
.icon-list-2-1:before {
  content: "\e489";
}
.icon-list-3:before {
  content: "\e48a";
}
.icon-list-4:before {
  content: "\e48b";
}
.icon-paragraph:before {
  content: "\e48c";
}
.icon-paste:before {
  content: "\e48d";
}
.icon-print-preview:before {
  content: "\e48e";
}
.icon-quote:before {
  content: "\e48f";
}
.icon-strikethrough:before {
  content: "\e490";
}
.icon-text:before {
  content: "\e491";
}
.icon-text-wrapping-1:before {
  content: "\e492";
}
.icon-text-wrapping-2:before {
  content: "\e493";
}
.icon-text-wrapping-3:before {
  content: "\e494";
}
.icon-underline:before {
  content: "\e495";
}
.icon-align-center:before {
  content: "\e496";
}
.icon-align-left:before {
  content: "\e497";
}
.icon-align-right:before {
  content: "\e498";
}
.icon-all-caps:before {
  content: "\e499";
}
.icon-arrange-2-1:before {
  content: "\e49a";
}
.icon-arrange-2-2:before {
  content: "\e49b";
}
.icon-arrange-2-3:before {
  content: "\e49c";
}
.icon-arrange-2-4:before {
  content: "\e49d";
}
.icon-arrange-3-1:before {
  content: "\e49e";
}
.icon-arrange-3-2:before {
  content: "\e49f";
}
.icon-arrange-3-3:before {
  content: "\e4a0";
}
.icon-arrange-3-4:before {
  content: "\e4a1";
}
.icon-arrange-3-5:before {
  content: "\e4a2";
}
.icon-arrange-4-1:before {
  content: "\e4a3";
}
.icon-arrange-4-2:before {
  content: "\e4a4";
}
.icon-arrange-4-3:before {
  content: "\e4a5";
}
.icon-arrange-5:before {
  content: "\e4a6";
}
.icon-consolidate-all:before {
  content: "\e4a7";
}
.icon-decrease-indent-1:before {
  content: "\e4a8";
}
.icon-decrease-indent-2:before {
  content: "\e4a9";
}
.icon-horizontal-page:before {
  content: "\e4aa";
}
.icon-increase-indent-1:before {
  content: "\e4ab";
}
.icon-increase-indent-2:before {
  content: "\e4ac";
}
.icon-justify:before {
  content: "\e4ad";
}
.icon-leading-1:before {
  content: "\e4ae";
}
.icon-leading-2:before {
  content: "\e4af";
}
.icon-left-indent:before {
  content: "\e4b0";
}
.icon-right-indent:before {
  content: "\e4b1";
}
.icon-small-caps:before {
  content: "\e4b2";
}
.icon-vertical-page:before {
  content: "\e4b3";
}
.icon-alt-mac:before {
  content: "\e4b4";
}
.icon-alt-windows:before {
  content: "\e4b5";
}
.icon-arrow-down:before {
  content: "\e4b6";
}
.icon-arrow-down-left:before {
  content: "\e4b7";
}
.icon-arrow-down-right:before {
  content: "\e4b8";
}
.icon-arrow-left:before {
  content: "\e4b9";
}
.icon-arrow-right:before {
  content: "\e4ba";
}
.icon-arrow-up:before {
  content: "\e4bb";
}
.icon-arrow-up-left:before {
  content: "\e4bc";
}
.icon-arrow-up-right:before {
  content: "\e4bd";
}
.icon-asterisk-1:before {
  content: "\e4be";
}
.icon-asterisk-2:before {
  content: "\e4bf";
}
.icon-back-tab-1:before {
  content: "\e4c0";
}
.icon-back-tab-2:before {
  content: "\e4c1";
}
.icon-backward-delete:before {
  content: "\e4c2";
}
.icon-blank:before {
  content: "\e4c3";
}
.icon-eject:before {
  content: "\e4c4";
}
.icon-enter-1:before {
  content: "\e4c5";
}
.icon-enter-2:before {
  content: "\e4c6";
}
.icon-escape:before {
  content: "\e4c7";
}
.icon-page-down:before {
  content: "\e4c8";
}
.icon-page-up:before {
  content: "\e4c9";
}
.icon-return-1:before {
  content: "\e4ca";
}
.icon-shift:before {
  content: "\e4cb";
}
.icon-shift-2:before {
  content: "\e4cc";
}
.icon-tab:before {
  content: "\e4cd";
}
.icon-apple:before {
  content: "\e4ce";
}
.icon-beer:before {
  content: "\e4cf";
}
.icon-boil:before {
  content: "\e4d0";
}
.icon-bottle-1:before {
  content: "\e4d1";
}
.icon-bottle-2:before {
  content: "\e4d2";
}
.icon-bottle-3:before {
  content: "\e4d3";
}
.icon-bottle-4:before {
  content: "\e4d4";
}
.icon-bread:before {
  content: "\e4d5";
}
.icon-burger-1:before {
  content: "\e4d6";
}
.icon-burger-2:before {
  content: "\e4d7";
}
.icon-cake-1:before {
  content: "\e4d8";
}
.icon-cake-2:before {
  content: "\e4d9";
}
.icon-champagne:before {
  content: "\e4da";
}
.icon-cheese:before {
  content: "\e4db";
}
.icon-cocktail-1:before {
  content: "\e4dc";
}
.icon-cocktail-2:before {
  content: "\e4dd";
}
.icon-cocktail-3:before {
  content: "\e4de";
}
.icon-coffee-cup:before {
  content: "\e4df";
}
.icon-coffee-cup-1:before {
  content: "\e4e0";
}
.icon-coffee-pot:before {
  content: "\e4e1";
}
.icon-deep-fry:before {
  content: "\e4e2";
}
.icon-energy-drink:before {
  content: "\e4e3";
}
.icon-espresso-machine:before {
  content: "\e4e4";
}
.icon-food-dome:before {
  content: "\e4e5";
}
.icon-fork-and-knife:before {
  content: "\e4e6";
}
.icon-fork-and-spoon:before {
  content: "\e4e7";
}
.icon-grape:before {
  content: "\e4e8";
}
.icon-grater:before {
  content: "\e4e9";
}
.icon-grill:before {
  content: "\e4ea";
}
.icon-hot-drinks-glass:before {
  content: "\e4eb";
}
.icon-hotdog:before {
  content: "\e4ec";
}
.icon-ice-cream-1:before {
  content: "\e4ed";
}
.icon-ice-cream-2:before {
  content: "\e4ee";
}
.icon-ice-cream-3:before {
  content: "\e4ef";
}
.icon-ice-drinks-glass:before {
  content: "\e4f0";
}
.icon-juicer:before {
  content: "\e4f1";
}
.icon-kitchen-timer:before {
  content: "\e4f2";
}
.icon-milk:before {
  content: "\e4f3";
}
.icon-orange:before {
  content: "\e4f4";
}
.icon-oven:before {
  content: "\e4f5";
}
.icon-pan-fry:before {
  content: "\e4f6";
}
.icon-pepper-salt:before {
  content: "\e4f7";
}
.icon-pizza:before {
  content: "\e4f8";
}
.icon-pop-corn:before {
  content: "\e4f9";
}
.icon-serving:before {
  content: "\e4fa";
}
.icon-soda:before {
  content: "\e4fb";
}
.icon-soda-can-1:before {
  content: "\e4fc";
}
.icon-soda-can-2:before {
  content: "\e4fd";
}
.icon-steam:before {
  content: "\e4fe";
}
.icon-tea-pot:before {
  content: "\e4ff";
}
.icon-thermometer-high:before {
  content: "\e500";
}
.icon-thermometer-low:before {
  content: "\e501";
}
.icon-thermometer-medium:before {
  content: "\e502";
}
.icon-water:before {
  content: "\e503";
}
.icon-wine:before {
  content: "\e504";
}
.icon-ambulance:before {
  content: "\e505";
}
.icon-beaker-1:before {
  content: "\e506";
}
.icon-beaker-2:before {
  content: "\e507";
}
.icon-blood:before {
  content: "\e508";
}
.icon-drug:before {
  content: "\e509";
}
.icon-first-aid:before {
  content: "\e50a";
}
.icon-hashish:before {
  content: "\e50b";
}
.icon-heart-pulse:before {
  content: "\e50c";
}
.icon-hospital-1:before {
  content: "\e50d";
}
.icon-hospital-2:before {
  content: "\e50e";
}
.icon-hospital-sign-1:before {
  content: "\e50f";
}
.icon-hospital-sign-2:before {
  content: "\e510";
}
.icon-hospital-sign-3:before {
  content: "\e511";
}
.icon-medicine:before {
  content: "\e512";
}
.icon-microscope:before {
  content: "\e513";
}
.icon-mortar-and-pestle:before {
  content: "\e514";
}
.icon-plaster:before {
  content: "\e515";
}
.icon-pulse-graph-1:before {
  content: "\e516";
}
.icon-pulse-graph-2:before {
  content: "\e517";
}
.icon-pulse-graph-3:before {
  content: "\e518";
}
.icon-red-cross:before {
  content: "\e519";
}
.icon-stethoscope:before {
  content: "\e51a";
}
.icon-syringe:before {
  content: "\e51b";
}
.icon-yin-yang:before {
  content: "\e51c";
}
.icon-balloon:before {
  content: "\e51d";
}
.icon-briefcase-lock:before {
  content: "\e51e";
}
.icon-card:before {
  content: "\e51f";
}
.icon-cards-1:before {
  content: "\e520";
}
.icon-cards-2:before {
  content: "\e521";
}
.icon-curtain:before {
  content: "\e522";
}
.icon-dice-1:before {
  content: "\e523";
}
.icon-dice-2:before {
  content: "\e524";
}
.icon-pacman:before {
  content: "\e525";
}
.icon-pacman-ghost:before {
  content: "\e526";
}
.icon-sign-1:before {
  content: "\e527";
}
.icon-smiley-happy:before {
  content: "\e528";
}
.icon-smiley-sad:before {
  content: "\e529";
}
.icon-smileys:before {
  content: "\e52a";
}
.icon-suitcase-1:before {
  content: "\e52b";
}
.icon-suitcase-2:before {
  content: "\e52c";
}
.icon-tetris:before {
  content: "\e52d";
}
.icon-ticket-1:before {
  content: "\e52e";
}
.icon-ticket-2:before {
  content: "\e52f";
}
.icon-ticket-3:before {
  content: "\e530";
}
.icon-virus:before {
  content: "\e531";
}
.icon-cloud-1:before {
  content: "\e532";
}
.icon-cloud-lightning:before {
  content: "\e533";
}
.icon-clouds:before {
  content: "\e534";
}
.icon-first-quarter-half-moon:before {
  content: "\e535";
}
.icon-full-moon:before {
  content: "\e536";
}
.icon-hail:before {
  content: "\e537";
}
.icon-heavy-rain:before {
  content: "\e538";
}
.icon-moon-cloud:before {
  content: "\e539";
}
.icon-rain:before {
  content: "\e53a";
}
.icon-rain-lightning:before {
  content: "\e53b";
}
.icon-snow:before {
  content: "\e53c";
}
.icon-sun:before {
  content: "\e53d";
}
.icon-sun-cloud:before {
  content: "\e53e";
}
.icon-thermometer:before {
  content: "\e53f";
}
.icon-third-quarter-half-moon:before {
  content: "\e540";
}
.icon-umbrella:before {
  content: "\e541";
}
.icon-waning-crescent-moon:before {
  content: "\e542";
}
.icon-waning-gibbous-moon:before {
  content: "\e543";
}
.icon-waxing-crescent-moon:before {
  content: "\e544";
}
.icon-waxing-gibbous-moon:before {
  content: "\e545";
}
.icon-bicycle:before {
  content: "\e546";
}
.icon-bus-1:before {
  content: "\e547";
}
.icon-bus-2:before {
  content: "\e548";
}
.icon-car-1:before {
  content: "\e549";
}
.icon-car-2:before {
  content: "\e54a";
}
.icon-car-3:before {
  content: "\e54b";
}
.icon-car-4:before {
  content: "\e54c";
}
.icon-helicopter:before {
  content: "\e54d";
}
.icon-mountain-bike:before {
  content: "\e54e";
}
.icon-pickup:before {
  content: "\e54f";
}
.icon-plane-1:before {
  content: "\e550";
}
.icon-plane-2:before {
  content: "\e551";
}
.icon-plane-landing:before {
  content: "\e552";
}
.icon-plane-takeoff:before {
  content: "\e553";
}
.icon-road:before {
  content: "\e554";
}
.icon-road-bike:before {
  content: "\e555";
}
.icon-rocket:before {
  content: "\e556";
}
.icon-scooter:before {
  content: "\e557";
}
.icon-ship:before {
  content: "\e558";
}
.icon-train:before {
  content: "\e559";
}
.icon-tram:before {
  content: "\e55a";
}
.icon-cactus:before {
  content: "\e55b";
}
.icon-clover:before {
  content: "\e55c";
}
.icon-flower:before {
  content: "\e55d";
}
.icon-hand-eco:before {
  content: "\e55e";
}
.icon-hand-globe:before {
  content: "\e55f";
}
.icon-leaf:before {
  content: "\e560";
}
.icon-light-eco:before {
  content: "\e561";
}
.icon-potted-plant-1:before {
  content: "\e562";
}
.icon-potted-plant-2:before {
  content: "\e563";
}
.icon-2-fingers-down-swipe:before {
  content: "\e564";
}
.icon-2-fingers-horizontal-swipe:before {
  content: "\e565";
}
.icon-2-fingers-left-swipe:before {
  content: "\e566";
}
.icon-2-fingers-omnidirectional-swipe:before {
  content: "\e567";
}
.icon-2-fingers-right-swipe:before {
  content: "\e568";
}
.icon-2-fingers-tab-hold:before {
  content: "\e569";
}
.icon-2-fingers-tap:before {
  content: "\e56a";
}
.icon-2-fingers-up-swipe:before {
  content: "\e56b";
}
.icon-2-fingers-vertical-swipe:before {
  content: "\e56c";
}
.icon-2finger-double-tap:before {
  content: "\e56d";
}
.icon-double-tap:before {
  content: "\e56e";
}
.icon-drag-down:before {
  content: "\e56f";
}
.icon-drag-horizontal:before {
  content: "\e570";
}
.icon-drag-left:before {
  content: "\e571";
}
.icon-drag-right:before {
  content: "\e572";
}
.icon-drag-up:before {
  content: "\e573";
}
.icon-drag-vertical:before {
  content: "\e574";
}
.icon-filck-down:before {
  content: "\e575";
}
.icon-flick-up:before {
  content: "\e576";
}
.icon-horizontal-flick:before {
  content: "\e577";
}
.icon-left-flick:before {
  content: "\e578";
}
.icon-omnidirectional-drag:before {
  content: "\e579";
}
.icon-omnidirectional-flick:before {
  content: "\e57a";
}
.icon-omnidirectional-swipe:before {
  content: "\e57b";
}
.icon-pinch:before {
  content: "\e57c";
}
.icon-right-flick:before {
  content: "\e57d";
}
.icon-rotate-clockwise:before {
  content: "\e57e";
}
.icon-rotate-counterclockwise:before {
  content: "\e57f";
}
.icon-spread:before {
  content: "\e580";
}
.icon-swipe-down:before {
  content: "\e581";
}
.icon-swipe-horizontal:before {
  content: "\e582";
}
.icon-swipe-left:before {
  content: "\e583";
}
.icon-swipe-right:before {
  content: "\e584";
}
.icon-swipe-up:before {
  content: "\e585";
}
.icon-swipe-vertical:before {
  content: "\e586";
}
.icon-tap:before {
  content: "\e587";
}
.icon-tap-hold:before {
  content: "\e588";
}
.icon-vertical-flick:before {
  content: "\e589";
}
.icon-arrow-1-1:before {
  content: "\e58a";
}
.icon-arrow-2-1:before {
  content: "\e58b";
}
.icon-arrow-3:before {
  content: "\e58c";
}
.icon-arrow-4:before {
  content: "\e58d";
}
.icon-arrow-5:before {
  content: "\e58e";
}
.icon-arrow-6:before {
  content: "\e58f";
}
.icon-arrow-7:before {
  content: "\e590";
}
.icon-arrow-8:before {
  content: "\e591";
}
.icon-arrow-9:before {
  content: "\e592";
}
.icon-arrow-10:before {
  content: "\e593";
}
.icon-arrow-11:before {
  content: "\e594";
}
.icon-arrow-12:before {
  content: "\e595";
}
.icon-arrow-13:before {
  content: "\e596";
}
.icon-arrow-14:before {
  content: "\e597";
}
.icon-arrow-15:before {
  content: "\e598";
}
.icon-arrow-16:before {
  content: "\e599";
}
.icon-arrow-17:before {
  content: "\e59a";
}
.icon-arrow-18:before {
  content: "\e59b";
}
.icon-arrow-19:before {
  content: "\e59c";
}
.icon-arrow-20:before {
  content: "\e59d";
}
.icon-arrow-21:before {
  content: "\e59e";
}
.icon-arrow-22:before {
  content: "\e59f";
}
.icon-arrow-23:before {
  content: "\e5a0";
}
.icon-arrow-24:before {
  content: "\e5a1";
}
.icon-arrow-25:before {
  content: "\e5a2";
}
.icon-arrow-26:before {
  content: "\e5a3";
}
.icon-arrow-27:before {
  content: "\e5a4";
}
.icon-arrow-28:before {
  content: "\e5a5";
}
.icon-arrow-29:before {
  content: "\e5a6";
}
.icon-arrow-30:before {
  content: "\e5a7";
}
.icon-arrow-31:before {
  content: "\e5a8";
}
.icon-arrow-32:before {
  content: "\e5a9";
}
.icon-arrow-33:before {
  content: "\e5aa";
}
.icon-arrow-34:before {
  content: "\e5ab";
}
.icon-arrow-35:before {
  content: "\e5ac";
}
.icon-arrow-36:before {
  content: "\e5ad";
}
.icon-arrow-37:before {
  content: "\e5ae";
}
.icon-arrow-38:before {
  content: "\e5af";
}
.icon-arrow-39:before {
  content: "\e5b0";
}
.icon-arrow-40:before {
  content: "\e5b1";
}
.icon-arrow-41:before {
  content: "\e5b2";
}
.icon-arrow-42:before {
  content: "\e5b3";
}
.icon-arrow-43:before {
  content: "\e5b4";
}
.icon-arrow-44:before {
  content: "\e5b5";
}
.icon-arrow-45:before {
  content: "\e5b6";
}
.icon-arrow-46:before {
  content: "\e5b7";
}
.icon-arrow-47:before {
  content: "\e5b8";
}
.icon-arrow-48:before {
  content: "\e5b9";
}
.icon-arrow-49:before {
  content: "\e5ba";
}
.icon-arrow-50:before {
  content: "\e5bb";
}
.icon-arrow-51:before {
  content: "\e5bc";
}
.icon-arrow-52:before {
  content: "\e5bd";
}
.icon-arrow-53:before {
  content: "\e5be";
}
.icon-arrow-54:before {
  content: "\e5bf";
}
.icon-arrow-55:before {
  content: "\e5c0";
}
.icon-arrow-56:before {
  content: "\e5c1";
}
.icon-arrow-57:before {
  content: "\e5c2";
}
.icon-arrow-58:before {
  content: "\e5c3";
}
.icon-arrow-59:before {
  content: "\e5c4";
}
.icon-arrow-60:before {
  content: "\e5c5";
}
.icon-arrow-61:before {
  content: "\e5c6";
}
.icon-arrow-62:before {
  content: "\e5c7";
}
.icon-arrow-63:before {
  content: "\e5c8";
}
.icon-arrow-64:before {
  content: "\e5c9";
}
.icon-arrow-65:before {
  content: "\e5ca";
}
.icon-arrow-66:before {
  content: "\e5cb";
}
.icon-arrow-67:before {
  content: "\e5cc";
}
.icon-arrow-68:before {
  content: "\e5cd";
}
.icon-arrow-69:before {
  content: "\e5ce";
}
.icon-arrow-70:before {
  content: "\e5cf";
}
.icon-arrow-71:before {
  content: "\e5d0";
}
.icon-arrow-72:before {
  content: "\e5d1";
}
.icon-arrow-circle-1:before {
  content: "\e5d2";
}
.icon-arrow-circle-2:before {
  content: "\e5d3";
}
.icon-arrow-circle-3:before {
  content: "\e5d4";
}
.icon-arrow-circle-4:before {
  content: "\e5d5";
}
.icon-arrow-circle-5:before {
  content: "\e5d6";
}
.icon-arrow-circle-6:before {
  content: "\e5d7";
}
.icon-arrow-circle-7:before {
  content: "\e5d8";
}
.icon-arrow-circle-8:before {
  content: "\e5d9";
}
.icon-arrow-circle-9:before {
  content: "\e5da";
}
.icon-arrow-circle-10:before {
  content: "\e5db";
}
.icon-arrow-circle-11:before {
  content: "\e5dc";
}
.icon-arrow-circle-12:before {
  content: "\e5dd";
}
.icon-arrow-circle-13:before {
  content: "\e5de";
}
.icon-arrow-circle-14:before {
  content: "\e5df";
}
.icon-arrow-circle-15:before {
  content: "\e5e0";
}
.icon-arrow-circle-16:before {
  content: "\e5e1";
}
.icon-arrow-circle-17:before {
  content: "\e5e2";
}
.icon-arrow-circle-18:before {
  content: "\e5e3";
}
.icon-arrow-circle-19:before {
  content: "\e5e4";
}
.icon-arrow-circle-20:before {
  content: "\e5e5";
}
.icon-arrow-circle-21:before {
  content: "\e5e6";
}
.icon-arrow-circle-22:before {
  content: "\e5e7";
}
.icon-arrow-circle-23:before {
  content: "\e5e8";
}
.icon-arrow-circle-24:before {
  content: "\e5e9";
}
.icon-arrow-circle-25:before {
  content: "\e5ea";
}
.icon-arrow-circle-26:before {
  content: "\e5eb";
}
.icon-arrow-circle-27:before {
  content: "\e5ec";
}
.icon-arrow-circle-28:before {
  content: "\e5ed";
}
.icon-arrow-circle-29:before {
  content: "\e5ee";
}
.icon-arrow-circle-30:before {
  content: "\e5ef";
}
.icon-arrow-delete-1:before {
  content: "\e5f0";
}
.icon-arrow-delete-2:before {
  content: "\e5f1";
}
.icon-arrow-dot-1:before {
  content: "\e5f2";
}
.icon-arrow-dot-2:before {
  content: "\e5f3";
}
.icon-arrow-dot-3:before {
  content: "\e5f4";
}
.icon-arrow-dot-4:before {
  content: "\e5f5";
}
.icon-arrow-dot-5:before {
  content: "\e5f6";
}
.icon-arrow-dot-6:before {
  content: "\e5f7";
}
.icon-arrow-rectangle-1:before {
  content: "\e5f8";
}
.icon-arrow-rectangle-2:before {
  content: "\e5f9";
}
.icon-arrow-rectangle-3:before {
  content: "\e5fa";
}
.icon-arrow-rectangle-4:before {
  content: "\e5fb";
}
.icon-arrow-rectangle-5:before {
  content: "\e5fc";
}
.icon-arrow-rectangle-6:before {
  content: "\e5fd";
}
.icon-arrow-rectangle-7:before {
  content: "\e5fe";
}
.icon-arrow-rectangle-8:before {
  content: "\e5ff";
}
.icon-arrow-rectangle-9:before {
  content: "\e600";
}
.icon-arrow-rectangle-10:before {
  content: "\e601";
}
.icon-arrow-rectangle-11:before {
  content: "\e602";
}
.icon-arrow-rectangle-12:before {
  content: "\e603";
}
.icon-arrow-rectangle-13:before {
  content: "\e604";
}
.icon-arrow-rectangle-14:before {
  content: "\e605";
}
.icon-arrow-rectangle-15:before {
  content: "\e606";
}
.icon-arrow-rectangle-16:before {
  content: "\e607";
}
.icon-arrow-rectangle-17:before {
  content: "\e608";
}
.icon-arrow-rectangle-18:before {
  content: "\e609";
}
.icon-arrow-rectangle-19:before {
  content: "\e60a";
}
.icon-arrow-rectangle-20:before {
  content: "\e60b";
}
