$circleSize: 35px;
// circle and label wrapper
$stepperItemWidth: $circleSize + 60px;
$connectorPullIn: 1.3em;

#ao-expert-content1, #ao-expert-content2 {
  label {
    font-size: 14px;
  }

  .ccform {
    label {
      font-size: 12px;
    }
  }
}

.stepper{
  display: table;
  width: 100%;
  position: relative;
  margin-bottom: 60px;

  .stepper-item, .stepper-connector{
    display: table-cell;
  }

  .stepper-item{
    width: $stepperItemWidth;
    text-align: center;

    &.step-current {
      .stepper-circle{
        background-color: $brand-success;
      }

      span {
        text-decoration: underline;
        font-weight: 600;
      }
    }

    .stepper-label{
      position: absolute;
      top: $circleSize + 5px;
      left: 0;
      text-align: center;
      width: 100%;
      display: inline-block;
    }
  }

  .stepper-connector{
    hr{
      height: 2px;
      margin: 0 -#{$connectorPullIn};
      transform: translateY(-0.3em);
      color: #ddd;
      background-color: #ddd;
      border: none;
    }
  }

  @media(min-width: 600px){
    .stepper-item{
      position: relative;

      .stepper-label{
        display: inline-block;
      }
    }
  }
}

.stepper-circle{
  width: $circleSize;
  height: $circleSize;
  display: inline-block;
  font-weight: bold;
  font-style: normal;
  color: #fff;
  line-height: 24px;
  text-align: center;
  background-color: $breadcrumb-color;
  border-radius: 50%;
  padding: 5px;
  font-size: 1.4rem;
}

.form-new-id {
  .form-group {
    width: 350px;
    margin: auto;
  }
}

.consent_row {
  display: flex;
  flex-direction: column;

  label {
    font-weight: 400;
    color: #FFF;
    margin-left: 5px;
    top: -6px;
    position: relative;
  }

  .ccform {
    margin-top: 20px;
  }

  .contractual-conditions {
    font-weight: 400;
    color: #FFF;
    margin-left: 5px;
  }

  input[type=checkbox] {
    width: 2rem;
    height: 2rem;
  }

  &>div {
    margin: 30px 0 0;
    background: #fff;
    position: relative;
    border-radius: 3px;

    &>div {
      padding: 10px;
    }

    h2 {
      color: $brand-success;
      font-size: 2rem;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 20px;
    }

    ul {
      padding-left: 15px;
    }
    
    &:before {
      content: '';
      display: block;
      height: 10px;
      width: 50%;
      background: $brand-success;
      border-top-left-radius: 3px;
    }

    label {
      color: $text-color;
    }

    .contractual-conditions {
      color: $text-color;

      p {
        margin: 0;
      }
    }
  }
}

#reassu-assu-name {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 30px;

  span {
    text-decoration: underline;
  }
}

#indra_ao_short_numberPlate {
  margin-bottom: 7px;
}

#gostep1 {
  margin-right: 10px;
}

.index-expert {
  .tooltip-inner {
    width: 400px;
    max-width: 400px;
  }

  .tooltip {
    top: 135px !important;
    left: calc(50% - 205px) !important;

    .tooltip-arrow {
      display: none;
    }
  }
}
