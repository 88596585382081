.page-create-offer {
  .abuse {
    h3 {
      margin: 0;
    }
    p {
      font-size: 14px;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }
  form {
    > .form-group:first-child {
      margin-top: $separator-space-top;
      margin-bottom: $separator-space-bottom;
    }

    [class^="col-"] {
      margin-bottom: 10px;

      @media only screen and (min-width: $screen-sm) {
        margin-bottom: 0;
      }
    }

    .control-label {
      font-size: 16px;
      font-weight: normal;
      text-align: left;
    }

    .radio-buttons {
      @media only screen and (min-width: $screen-sm) {
        text-align: right;
      }
    }

    .has-error label {
      color: $text-color;
    }

    .upload label.upload-area{
      input[type="file"] {
        display: block;
        position: relative;
        opacity: 0;
      }

      &[data-id="5"],&[data-id="3"] {

        input[type="file"]{
          top: -140px;

          @media only screen and (min-width: $screen-md) {
            top: -171px;
          }
        }
      }

      &[data-id="1"],&[data-id="2"] {

        input[type="file"]{
          top: -140px;

          @media only screen and (min-width: $screen-md) {
            top: -171px;
          }
        }
      }

      &[data-id="4"] {

        input[type="file"]{
          top: -140px;
          @media only screen and (min-width: $screen-md) {
            top: -199px;
          }
        }
      }

      &.complete {
        &:not([data-id="4"]) {

          max-height: 180px;
          @media only screen and (min-width: $screen-md) {
            max-height: 176px;
          }

          input[type="file"]{
            top: -131px;

            @media only screen and (min-width: $screen-md) {
              top: -161px;
            }
          }
        }

        &[data-id="4"] {

          max-height: 175px;

          @media only screen and (min-width: $screen-sm) {
            max-height: 145px;
          }

          @media only screen and (min-width: $screen-md) {
            max-height: 175px;
          }

          input[type="file"]{
            top: -131px;
            @media only screen and (min-width: $screen-md) {
              top: -160px;
            }
          }
        }
      }
    }
  }
  .disabled{
    cursor: not-allowed;
    pointer-events: none;
  }

  .input-documents-dropzone {
    width:100%;
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index: 50;
    display: block !important;
    opacity: 0 !important;
  }

  div.input-numbers.required > i {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 50%;
    right: 10px;
  }

  div.input-numbers > i {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 50%;
    right: 10px;
  }

  div.input-numbers > input {
    text-align: left;
    width: 100%;
  }

  .admin-can-edit-info {
    padding: 20px;
    display: flex;
    justify-content: center;
    border: 1px solid $gray-light;
    width: 56%;
    p {
      padding: 10px;
      margin: 0 0 0 10px;
    }
    i {
      &:before {
        top: 4px;
        font-weight: bold;
        font-size: 30px;
      }
    }
  }

  .styleWhenAdminCanEdit {
    .form-control[disabled] {
      opacity: 0.65;
    }
    i {
      color: #b5bdc2;
    }
    .btn-group {
      cursor: not-allowed;
      .btn {
        background-color: #eaedee;

      }
    }
    .styleUploadWhenAdminCanEdit {
      cursor: not-allowed;
    }
    .styleNoMinimumPriceWhenAdminCanEdit {
      cursor: not-allowed;
    }
    .styleAttachmentsWhenAdminCanEdit {
      cursor: not-allowed;
    }
    .add-attached-document-container {
      pointer-events: none;
    }

    .attached-document {
      .input-group-btn {
        cursor: not-allowed;
        .btn {
          pointer-events: none;
        }
      }
    }

    #indra_ao_fee_0 + label {
      cursor: not-allowed;
      opacity: 0.65;
    }
    #indra_ao_fee_1 + label {
      cursor: not-allowed;
      opacity: 0.65;
    }
    .remove-document-container {
      pointer-events: none;
    }
  }

  @media screen and (max-width: $screen-sm) {
    .admin-can-edit-info {
      width: 100%;
    }
  }
}
