.bootstrap-select.form-control {
  height: auto;
}

.bootstrap-select, .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select.btn-group .btn .caret {
  display: none;
}

.bootstrap-select.btn-group > .btn {
  background: #FFF url(/assets/img/dropdown.svg) no-repeat right 15px center;
  background-size: 17px 9px;
  padding-right: 35px;
  color: $text-color;
  border-color: $input-border;
}

.has-error .bootstrap-select .form-control {
  border-color: #cd2200;
}

.has-error .bootstrap-select .form-control {
  border-color: #cd2200;
}

select.form-control.mobile-device {
  position: absolute;
}

select.form-control.mobile-device {
  position: absolute;
}

.bootstrap-select.dropdown {
  > .btn {
    border: none;
    border-radius: $btn-border-radius-base $btn-border-radius-base 0 0;
    font-size: 16px;
    padding: 10px 35px 10px 10px;
    height: 50px;
    margin-top: 17px;

    &:hover, &:active, &:focus {
      outline: 0 !important;
    }
  }

  .dropdown-menu {
    border: none;
    margin-top: -1px;

    li {
      padding: 5px 10px;

      p {
        font-size: 14px;
        text-transform: uppercase;
        color: $text-color;
        padding: 10px;
        background-color: #eaedee;
        overflow: hidden;
        line-height: 25px;
      }
    }
  }
}