
//====================================
// BOOTSTRAP
//====================================

//== Color
$grey-border: #D6DADD;
$grey-bg: #eaedee;
$brand-success: #97be0d;
$brand-danger: #cd2200;
$border-custom-color: #aeb7bc;
$strong-red: #c2000e;
$lime-green: #008025;
$very-light-gray: #e7e7e7;

//== Scaffolding
$text-color: #344B57;
$body-bg-dark: $text-color;

//== Iconography
$icon-font-path: "/assets/fonts/bootstrap/";
$roboto-font-path: "/assets/fonts";

//== Typography
$font-size-base: 12px;
$font-size-h1: 36px;
$font-family-sans-serif: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-icon: "streamline", sans-serif;

//== NavBar
$navbar-height: 80px;
$navbar-default-bg: #fff;
$navbar-default-border: $grey-border;
$navbar-default-color: $text-color;
$nav-link-padding: 10px 10px;
$navbar-margin-bottom: 0;
$navbar-border-radius: 0;

//== Section
$separator-space: 25px;
$separator-space-top: $separator-space;
$separator-space-bottom: $separator-space;

//== Button
$btn-default-color: #FFF;
$btn-default-bg: #99a5ab;
$btn-default-border: $btn-default-bg;
$btn-success-bg: $brand-success;
$btn-success-border: $btn-success-bg;

//== Form
$input-color-placeholder: #b5bdc2;
$input-height-base: 40px;


//== Input form
$input-group-addon-bg: #FFF;
$input-height-small: 35px;

//== Form states and alerts
$state-info-text: #FFFFFF;
$state-info-bg: $body-bg-dark;
$state-success-text: $brand-success;
$state-danger-text: $brand-danger;

//== Breadcrumbs
$breadcrumb-color: #9ba2a6;
$breadcrumb-active-color: $text-color;
$breadcrumb-bg: $grey-bg;
$breadcrumb-separator: '>';

//== Tooltip
$tooltip-bg: $brand-danger;

//== DropDown
$dropdown-link-active-bg: $brand-success;

//== Pagination
$pagination-color:                     $text-color;
$pagination-bg:                        #fff !default;

$pagination-active-color:              #fff !default;
$pagination-active-bg:                 $body-bg-dark !default;
$pagination-active-border:             $body-bg-dark !default;

//===================================
// ANIMATE.CSS
//===================================

$use-fadeInDown: true;
$use-fadeOutUp: true;