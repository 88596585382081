.dataTables_wrapper {
  .dataTables_info {
    color: $btn-default-bg;
  }

  .dataTables_length {
    display: inline-block;
    margin-right: 15px;

    label {
      font-size: 16px;

      select {
        line-height: normal;
        color: $btn-default-bg;
      }
    }
  }

  .btn.buttons-colvis {
    color: $btn-default-bg;
    font-size: 16px;
    background: #FFF url(/assets/img/dropdown.svg) no-repeat right 15px center;
    background-size: 17px 9px;
    padding-right: 40px;
    border-color: $input-border;

    &:focus {
      outline: none;
    }
  }

  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    color: $brand-success;
  }

  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after {
    opacity: 1;
  }

  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    opacity: 0;
  }

  table.dataTable thead .sorting:after {
    opacity: 0.4;
  }

  .table > thead:first-child > tr:first-child > th {
    color: #FFF;
    background-color: $body-bg-dark;
  }

  table.dataTable {
    font-size: 16px;
  }
}

ul.dt-button-collection.dropdown-menu{
  max-height: 240px;
  overflow-y: auto;

  > li.active {
  &:focus {
    outline: none;
  }

  > a {
    cursor: pointer;
    position: relative;
    color: $text-color;
    background-color: transparent;

    &:before {
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -8.5px;
      display: block;
      font-family: $font-family-icon;
      content: "\e2a1";
      color: $brand-success;
    }

    &:hover {
      background-color: $dropdown-link-hover-bg;
    }
  }
}
}

