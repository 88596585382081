.modal-content {
  border-radius: $border-radius-base;
}

.modal-header {
  color: #FFF;
  background-color: $text-color;
  border-bottom: 1px solid $text-color;

  .close {
    font-size: 22px;
    color: #FFF;
    opacity: 0.75;

    &:hover {
      opacity: 1;
    }
  }
}

.modal-footer {
  border-top: none;
}

.modal-upload {
  @media only screen and (max-width: $screen-sm) {
    display: none !important;
  }

  .modal-dialog {
    width: 785px;
  }
}