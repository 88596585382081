.bo-manage-users {
  form .control-label {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
  }
  form .radio-buttons {
    text-align: right;
  }
  form .attached-document {
    padding: 0;
    background: none;
  }
}