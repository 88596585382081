.attached-document, .add-attached-document {
  background-color: #FFF;
}

.attached-document {
  padding: 10px 50px 10px 10px;
  margin-bottom: 10px;
  font-size: 16px;

  label {
    font-weight: normal;

    @include hidden();
  }

  .label {
    word-wrap: break-word;
    word-break: break-all;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .has-error {
    font-size: 12px;
  }

  input[type="file"] {
    display: none;
  }

  .remove-document-container {
    position:relative;
    position: relative;
    top: -85px;
    right: -50px;
  }
  .corner {
    position: absolute;
    top: 5px;
    right: 10px;
    border: 1px solid $brand-success;
    border-radius: 50%;
    padding: 2px 6px;
    cursor: pointer;

    &:hover {
      color: #FFF;
      background-color: $brand-success;
    }
  }

  @include hidden();
}

.add-attached-document {
  display: block;
  color: $text-color;
  font-size: 16px;
  padding: 10px;

  &:hover {
    text-decoration: none;
  }

  @include hidden();
}