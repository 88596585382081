.map-header {
  background-color: $text-color;
  .ribbon .pull-right {
    cursor: pointer;
  }

  .map-pull-down {
    display: none;
  }
}
.map {
  position: relative;
  background: $grey-bg url("/assets/img/map-loader.gif") no-repeat center center;

  #mapContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 0;

    @media only screen and (max-width: $screen-sm) {
      display: none;
    }
  }

  .under-map-cover {
    position: relative;
    z-index: 1;
  }

  .infoBox {
    background-color: #fff;
    //border:1px solid $brand-success;
    border-radius:50%;
    cursor: pointer!important;
    > img {
      display: none;
    }
    text-align: center;
    font-size:14px;

    &.active {
      border: 4px  solid #996699;
      box-shadow: 0 0 20px 3px $border-custom-color;
      z-index: 100;
    }
  }
  .info-box {
    /*display: table-cell;
    vertical-align: middle;*/
    position: absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    padding: 8px;


    img {
      display: block;
      margin: 0 auto 5px auto;
      height: 30px;
      width: 20px;
    }
    p {
      white-space: nowrap;
      text-transform: uppercase;
    }
  }
}
.under-map {
  padding-top: 20px;
  background: white;

  > .form-group {
    padding-left: 20px;
    padding-right: 20px;
  }

  .under-map-end {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid $grey-border;

    i {
      font-size: 1.8em;
      margin-right: 7px;
    }

    span {
      font-size: 0.63em;
      font-weight: bolder;
    }

  }

  @media only screen and (max-width: $screen-sm) {

    .form-inline {
      .form-group {
        display: inline-block;
      }
      .tiny {
        width: 140px;
      }
    }

  }
}