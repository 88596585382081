.breadcrumbs-cover {
  background-color: $breadcrumb-bg;
}

.breadcrumb {
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 0;

  li {
    font-size: 10px;
    text-transform: uppercase;
     a {
       color: $breadcrumb-color;
     }
  }
}