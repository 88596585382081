.page-detail {
    #token_process {
        margin-bottom: 15px;
        .btn {
            padding: 2px 5px;
            font-size: 12px;
        }
        hr {
            border: 1px solid $grey-border;
        }
    }

    .minimum {
        margin-top: 5px;
    }

    .abuse .form-control {
        height: 45px;

      .btn [class^="icon-"] {
        display: inline-block;
        font-size: 18px;
        padding: 5px;
        background-color: $brand-success;
        color: #FFF;
        border-radius: $border-radius-base;
        margin-right: 10px;
      }
    }

    .abuse textarea.form-control {
      height: auto;
    }

    /* The Modal (background) */
    .modal-token {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 3; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    }

    /* Modal Content/Box */
    .modal-content-token {
        border-radius: 10px;
        background-color: #fefefe;
        margin: 15% auto; /* 15% from the top and centered */
        padding: 20px 20px 120px 20px;
        border: 1px solid #888;
        width: 50%; /* Could be more or less, depending on screen size */
        position: relative;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
        animation-name: animatetop;
        animation-duration: 0.4s;

        /* The Close Button */
        .close {
            color: #aaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
        }
    }

    /* Add Animation */
    @keyframes animatetop {
        from {top: -300px; opacity: 0}
        to {top: 0; opacity: 1}
    }


    /*   ******************************************************************************************* */
    /*   ******************************************************************************************* */

    .alert-danger {
        color: $strong-red;
        background-color: lighten($strong-red,60);
        border-color: lighten($strong-red,55);
        border-left: 3px solid $strong-red;
        .icon {
            font-weight: bold;
            &:before {
                content: unquote("\"\\f12a\"");
                //font-family: 'FontAwesome';
                display: inline-block;
                position: relative;
                margin-right: 10px;
            }
        }
    }

    .text-center {
        text-align: center;
    }

    //.modal-wrap {
    .modal-content-token {
        transition: transform 300ms ease-in-out;
    }

    .modal-header-token {
        height: 45px;
        background: $very-light-gray;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 20px;
            margin: 5px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.2);
            transition: all 300ms linear;
            &.is-active {
                background: rgba($lime-green, 0.5);
            }
            &.is-valid {
                cursor: pointer;
                background: $lime-green;
                &:before {
                    content: '\e2a1';
                    font-family: "streamline", sans-serif;
                    font-weight: 900;
                    color: white;
                    display: block;
                    position: relative;
                    animation: itemScale 200ms linear;
                }
            }
        }
        h3 {
            margin: 0px;
        }
    }

    .modal-bodies {
        .btn-token {
            width: 100%;
        }
        position: relative;
        perspective: 1000px;
        .form-group{
            margin-bottom: 10px;
        }
    }

    .modal-body-token {
        background: white;
        border-top: 0px;
        margin-bottom: 50px;
        position: absolute;
        top: 0;
        display: none;
        box-sizing: border-box;
        width: 100%;
        transform-origin: top left;
        &.is-showing {
            display: block;
        }
    }

    // -------------------------
    // JavaScript Classes
    // -------------------------
    .popover {
        border-radius: 0px;
        border-color: $lime-green;
        width: 100%;
        > .arrow {
            border-left-color: $lime-green !important;
        }
    }

    .popover-title {
        border-radius: 0px;
        border-color: $lime-green;
        background-color: $very-light-gray;
    }

    .popover-content {
        border-radius: 0px;
    }

    .animate-out-to-left {
        animation: out-left 600ms ease-in-out forwards;
    }

    .animate-out-to-right {
        animation: out-right 600ms ease-in-out forwards;
    }

    .animate-in-from-left {
        animation: in-left 500ms ease-in-out forwards;
        display: block;
    }

    .animate-in-from-right {
        animation: in-right 500ms ease-in-out forwards;
        display: block;
    }

    .animate-up {
        transform: translateY(-100vh) rotate(30deg);
    }

    .input-error {
        border-color: $strong-red !important;
    }

    .shake {
        animation: shake 500ms linear forwards;
    }

    // -------------------------
    // Keyframes
    // -------------------------
    @keyframes out-left {
        0% {
            transform: translateX(0px);
            opacity: 1;
        }
        100% {
            transform: translateX(-100vw);
            opacity: 0;
        }
    }

    @keyframes out-right {
        0% {
            transform: translateX(0px);
            opacity: 1;
        }
        100% {
            transform: translateX(100vw);
            opacity: 0;
        }
    }

    @keyframes in-left {
        0% {
            transform: translateX(-100vw);
            opacity: 0;
        }
        100% {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    @keyframes in-right {
        0% {
            transform: translateX(100vw);
            opacity: 0;
        }
        100% {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    @keyframes shake {

        0% {
            transform: translateX(0px);
        }
        10% {
            transform: translateX(-5px);
        }
        20% {
            transform: translateX(0px);
        }
        30% {
            transform: translateX(5px);
        }
        40% {
            transform: translateX(0px);
        }
        50% {
            transform: translateX(-5px);
        }
        60% {
            transform: translateX(0px);
        }
        70% {
            transform: translateX(5px);
        }
        80% {
            transform: translateX(0px);
        }
        90% {
            transform: translateX(-5px);
        }
        100% {
            transform: translateX(0px);
        }
    }

    @keyframes itemScale {
        0% {
            opacity: 0;
            transform: scale(0);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    //**************************************************************************************
    //* start of ao edit panel on /annonce/{id}?from=bo
    //**************************************************************************************
    .edit-panel-ao, .resend-email {
        /* Style the button that is used to open and close the collapsible content */
        .collapsible {
            cursor: pointer;
            padding: 18px;
            width: 100%;
            border: none;
            text-align: left;
            outline: none;
            font-size: 15px;
            transition: all 0.2s ease-out;
        }

        .panel-title:after {
            content: '\002B'; /* Unicode character for "plus" sign (+) */
            font-size: 20px;
            float: right;
        }

        /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
        .active, .collapsible:hover {
            background-color: $grey-bg;
            color: $text-color;

        }

        .active {
            .panel-title:after {
                color: #344b57;
                font-size: 20px;
                content: "\2212"; /* Unicode character for "minus" sign (-) */
            }
        }

        /* Style the collapsible content. Note: hidden by default */
        .content {
            display: none;
            padding: 18px;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
        }

        //**************************************************************************************
        .dropdown-menu {
            position: relative;
        }
        .panel-heading {
            border-radius: 4px;
        }
    }
    .edit-panel-ao {
        p {
            font-weight: 700;
        }
        i {
            padding: 0 15px;
            font-size: 27px;
            &:before {
                font-weight: bold;
            }
        }
        .form-control {
            margin-bottom: 15px;
        }
        .panel-body {
            div:first-child {
                display: flex;
            }
        }
    }
    //**************************************************************************************
    //* end of ao edit panel on /annonce/{id}?from=bo
    //**************************************************************************************

}

.dl-horizontal {
    dt {
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
    }
}

.page-detail {
    .tooltip {
        .tooltip-arrow {
            border-right-color: $text-color;
        }
        .tooltip-inner {
            color: #fff;
            background-color: $text-color;
        }
    }
}