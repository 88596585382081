.subnav-select {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;

  &:before {
    content: "";
    left: -15px;
    width: 2px;
    height: 100%;
    display: block;
    position: absolute;
    background: $breadcrumb-color;
  }

  .picto-users {
    position: absolute;
    top: 6px;
    left: 0;
    width: 25px;
    height: 25px;
    fill: $breadcrumb-color;
  }

  #ao_search_arrow {
    position: absolute;
    top: 10px;
    right: 0;
    width: 18px;
    height: 18px;
    fill: $breadcrumb-color;
    transition: all .25s ease;
  }

  &.opened {
    #ao_search_arrow {
      transform: rotate(180deg);
    }
  }

  &>div {
    margin-left: 50px;

    &>span {
      font-size: 1.1rem;
      font-weight: bolder;
    }

    &>div {
      font-size: 1.65rem;
      color: $btn-default-bg;
      font-weight: 700;

      &>span {
        font-weight: 400;
      }
    }
  }
}

#ao_search_filters {
  display: none;
  position: absolute;
  z-index: 50;
  top: 70px;
  left: 0;
  width: calc(100% - 16px);
  background: white;
  border: 1px solid $btn-default-bg;

  .mside {
    margin-left: 15px;
    margin-right: 15px;
  }

  #ao_search_reset {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    z-index: 55;
    fill: $grey-border;
    cursor: pointer;
    transition: all .25s ease;

    &:hover, &:focus {
      fill: black;
    }
  }

  #no_select_experts {
    display: none;
    font-weight: 100;
  }

  .ao-search-inner-results {
    overflow: auto;
    max-height: 50vh;
    margin-top: 15px;

    p {
      font-weight: 600;
      font-size: 1.25rem;
      color: $btn-default-bg;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      a {
        color: $text-color;
        text-decoration: none;
        display: block;
        padding: 6px 15px;
        background: white;
        transition: all .25s ease;

        &:hover, &:focus {
          background: lighten($very-light-gray, 4%);
        }
      }

      li {
        position: relative;
        margin-left: 18px;
        font-weight: 900;

        &>span {
          background: $grey-border;
        }

        &:before {
          content: '';
          position: absolute;
          display: block;
          left: -18px;
          top: 4px;
          height: 10px;
          width: 10px;
          background: $grey-border;
          border-radius: 50%;
          transition: all .25s ease;

          //&:hover, &:focus, &.activ {
          &.activ {
            background: $btn-success-bg;
          }
        }

        //&:hover, &:focus, &.activ {
        &.activ {
          &:before {
            background: $btn-success-bg;
          }
        }
      }
    }
  }

  .input-group-addon {
    border-radius: 0;
    border-color: $state-info-bg;

    svg {
      fill: $state-info-bg;
      height: 24px;
      width: 24px;
    }
  }

  input {
    padding-left: 0;
    padding-right: 35px;
    border-radius: 0;
    border-left: none;
    border-right: 1px solid;
    border-color: $state-info-bg;

    &:focus {
      box-shadow: none;
      border-color: $state-info-bg;
    }
  }

  &>div {
    margin: 15px 0;
  }
}

#ao_search_filter_bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}

#ao_search_return {
  display: none;
  font-weight: 600;
  color: $btn-default-bg;
  cursor: pointer;

  svg {
    fill: $btn-default-bg;
    top: 4px;
    left: 0;
    margin-right: 7px;
    position: relative;
    width: 15px;
    height: 15px;
    transition: all .25s ease;
    transform: rotate(180deg);
  }

  &:hover, &:focus {
    svg {
      left: -4px;
    }
  }

  span {
    border-bottom: 2px solid $btn-default-bg;
  }
}
