.page-rgpd table{
  margin: 5%;
}

.page-rgpd td{
  border: solid 2px lightgrey;
  text-align: justify;
  padding: 5px;
}

.page-rgpd th{
  border: solid 2px lightgrey;
  text-align: center;
  padding: 5px;
}

.page-rgpd p:not(.pre-links){
  margin-top: 2%;
  margin-bottom: 2%;
}

.page-rgpd .main-content{
  padding: 2%;
  font-size: 14px;
}