$quadriptych-separator-color: #ADB6BB;
$quadriptych-height: 46px;
$quadriptych-img-width: 32px;
$quadriptych-img-offset: 15px;
$quadriptych-space-bottom: 10px;
$multiple: 1.3;

.quadriptych {
  display: block;
  padding-left: 0;
  margin-top: $separator-space-top / $multiple;
  margin-bottom: $separator-space-bottom / $multiple;
  padding-top: $separator-space-top / $multiple;
  padding-bottom: $separator-space-bottom / $multiple;
  border-top: 1px solid $quadriptych-separator-color;
  border-bottom: 1px solid $quadriptych-separator-color;

  @media only screen and (min-width: $screen-sm) {
    margin-top: $separator-space-top;
    margin-bottom: $separator-space-bottom;
    padding-top: $separator-space-top;
    padding-bottom: $separator-space-bottom;
  }

  li {
    display: block;
    font-size: 1.4rem;
    position: relative;
    padding-left: $quadriptych-img-offset + $quadriptych-img-width + 25px;
    height: $quadriptych-height;
    margin-bottom: $quadriptych-space-bottom;

    @media only screen and (min-width: $screen-sm) {
      height: $quadriptych-height * $multiple;
    }

    @media only screen and (min-width: $screen-sm) {
      margin-bottom: 0;
    }

    img  {
      position: absolute;
      left: $quadriptych-img-offset;
      width: $quadriptych-img-width;
      height: $quadriptych-height;;

      @media only screen and (min-width: $screen-sm) {
        width: $quadriptych-img-width * $multiple;
        height: $quadriptych-height * $multiple;
      }
    }

    > .quadriptych-table {
      display: table;
      height: 100%;

      > .quadriptych-cell {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}