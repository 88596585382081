@mixin hidden-accessibility() {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

@mixin hidden () {
  &.off {
    display: none;
  }

  &.off.on, .on {
    display: block;
  }
}

// Placeholder additionnals
@mixin placeholder-additionnals() {
  // Firefox
  &::-moz-placeholder {
    font-style: italic;
  }
  &:-ms-input-placeholder { font-style: italic; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { font-style: italic; } // Safari and Chrome
}