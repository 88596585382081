.news-flash {
  @media only screen and (min-width: $screen-md) {
    position: absolute;
    left: -95px;
    top: 0;
    padding: 40px 20px 40px 60px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 85px;
      background-color: $brand-success;
    }

    .news-flash-wrapper {
      display: inline-block;
      width: 155px;
    }

    p:first-child {
      margin-bottom: 20px;
    }
  }

  @media only screen and (min-width: $screen-lg) and (max-width: 1280px),
  only screen and (max-width: 1060px) {
    left: 0;
  }

  @media only screen and (max-width: $screen-md) {
    display: none !important;
  }

  background-color: #FFF;
  color: $text-color;
  font-size: 14px;

  .title {
    font-size: 24px;
    font-weight: lighter;
    margin: 0;
    text-transform: uppercase;
    color: $brand-success;
  }
}