.btn {
  font-size: $font-size-base + 8px;
  img, [class^="icon-"] {
    margin-right: 20px;
  }
  [class^="icon-"] {
    font-size: $font-size-base + 12px;
    line-height: 20px;
  }

  &.btn-icon [class^="icon-"] {
    margin-right: 0;
  }
}

.btn-lg {
  @include button-size($padding-large-vertical+4px, $padding-large-horizontal+4px, $font-size-large+5px, $line-height-large, $btn-border-radius-large);

  [class^="icon-"] {
    font-size: $font-size-large+15px;
    line-height: 20px;
  }
}

.btn-sm {
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small+5px, $line-height-small, $btn-border-radius-base);
}

.btn {
  &.softdisabled {
    cursor: default;
    background-color: $breadcrumb-color;
    border-color: $breadcrumb-color;

    * {
      color: white;
    }
  }
}

.btn-actions {
  .btn {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}