h2 {
  font-size: 20px;
  color: $brand-success;
  text-transform: uppercase;
  font-weight: bold;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

p, li {
  font-size: 14px;
}
