.page-error {
    .code {
      float: right;
      color: $grey-border;
      font-size: 150px;

      small {
        font-size: 80px;
      }
    }
}