.navbar {
  @media only screen and (max-width: $screen-sm) {
    min-height: 0;
  }
}

.navbar.navbar-default {
  border-width: 0;
  border-bottom-width: 1px;
}

.navbar-header {
  @media only screen and (max-width: $screen-sm) {
    border-bottom: 1px solid $navbar-default-border;
  }
}

.navbar-brand {
  display: block;
  float: none;
  padding-top: (($navbar-height - $line-height-computed) / 4);

  > img {
    margin: 0 auto;
  }

  @media only screen and (min-width: $screen-sm) {
    float: left;
    border-right: 1px solid $navbar-default-border;
  }
}

.navbar-text {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;

  @media only screen and (min-width: $screen-sm) {
    display: block;
    font-size: 20px;
    margin-top: 26px;
    margin-bottom: 26px;
  }

  p {
    margin-bottom: 0;
  }
}

.navbar-text.navbar-right {
  font-weight: lighter;
}

.nav.navbar-nav {
  @media only screen and (max-width: $screen-sm) {
    margin-top: 0;
    margin-bottom: 0;
  }

  > li {
    @media only screen and (max-width: $screen-sm) {
      float: left;
      width: 25%;
      padding: 5px;
      border-right: 1px solid $navbar-default-border;
      text-align: center;

      &:last-child {
        border-right: 0;
      }
    }

    @media only screen and (min-width: $screen-sm) {
      border-left: 1px solid $navbar-default-border;
      height: $navbar-height;
    }

    > a {
      min-height: 55px;
      @media only screen and (min-width: $screen-sm) {
        height: $navbar-height;
        padding-top: 23px;
        padding-bottom: 23px;
      }

      > [class^="icon-"] {
        font-size: 30px;
        position: relative;

        .sup {
          position: absolute;
          top: -10px;
          left: -7px;
          color: #FFF;
          background-color: $brand-success;
          padding: 3px 8px;
          font-size: 14px;
          border-radius: 50%;
        }

        sub {
          position: absolute;
          font-size: 30px * 0.65;
          background: #FFF;
          color: $brand-success;
          font-weight: bold;

          @media only screen and (min-width: $screen-sm),
          only screen and (max-width: $screen-sm) {
            right: -22px * 0.65 / 2;
            bottom: -6px * 0.65 / 2;
          }

          @media only screen and (min-width: $screen-md) {
            right: -30px * 0.65 / 2;
            bottom: -30px * 0.65 / 2;
          }
        }
      }

      &:hover {
        background-color: $brand-success;
        color: #FFF;

        > [class^="icon-"] {
          color: #FFF;

          .sup {
            color: $brand-success;
            background-color: #FFF;
          }

          sub {
            color: #FFF;
            background: $brand-success;
          }
        }
      }
    }
  }

  .long {
    position: relative;

    @media only screen and (min-width: $screen-sm) {
      border-left: none;
      border-top: 2px solid $brand-success;
      width: 80px;
      text-align: center;
    }

    @media only screen and (min-width: $screen-md) {
      padding-left: 55px;
      width: 160px;
      text-align: left;

      > [class^="icon-"] {
        position: absolute;
        left: 10px;
      }
    }

    .sup.wrap {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }

  .short {
    @media only screen and (min-width: $screen-sm) {
      border-left: none;
      border-top: 2px solid transparent;
      width: 80px;
      text-align: center;
    }
  }

  .long, .short {
    > [class^="icon-"] {

      @media only screen and (min-width: $screen-sm) {
        margin-right: 10px;
        margin-right: 0;
      }
    }
  }

  &.admin > li {
    @media only screen and (max-width: $screen-sm) {
      width: 20%;
    }
  }
}