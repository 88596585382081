.back {
  position: absolute;
  top: 4px;
  left: -15px;
  padding: 5px 8px;
  font-size: 20px;
  background-color: $brand-success;
  color: #FFF;
  cursor: pointer;

  @media screen and (min-width: $screen-sm) {
    display: none;
  }
}

.back-cover {
  position: relative;

  @media screen and (max-width: $screen-sm) {
    padding-left: 25px;
  }
}

.navsvg {
  svg {
    width: 28px;
    height: 28px;
    fill: $text-color;
  }

  &:hover {
    svg {
      fill: white;
    }
  }
}
