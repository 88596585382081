.spinner {
  width: 100px;
  text-align: center;
}

.spinner > div {
  width: 16px;
  height: 16px;
  background-color: $brand-success;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.7s infinite ease-in-out both;
  margin-right: 5px;
}

.spinner .spinner-item1 {
  animation-delay: -0.60s;
}

.spinner .spinner-item2 {
  animation-delay: -0.40s;
}

.spinner .spinner-item3 {
  animation-delay: -0.20s;
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0;
  } 40% {
      transform: scale(1.0);
      opacity: 1;
    }
}