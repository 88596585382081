.page-index, .page-login {
  .header {
    background: transparent url(/assets/img/header-background.jpg) no-repeat center center;
    background-size: cover;

    > .container {
      color: #FFF;
      padding-top: 120px;
      padding-bottom: 120px;
      position: relative;

      .forgot-password {
        float: right;

        a {
          color: white;
        }
      }
    }

    h1 {
      font-weight: lighter;
    }

    h1, .header-sub-form {
      margin-bottom: 40px;
    }

    .radio-buttons {
      margin-bottom: 20px;

      .radio-button {
        margin-right: 5px;
        label {
          font-weight: normal;
          @media only screen and (min-width: $screen-lg) {
            padding: 10px 15px;
          }
        }
      }
    }

    .header-sub-form {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    .row {
      margin-bottom: 20px;
    }

    [class^="col-"] {
      .form-group label, .form-group .form-control, .header-sub-form {
        font-size: 20px;
      }

      .form-group label {
        font-weight: normal;
        color: #FFF;
        margin-left: 5px;
      }

      .form-control {
        height: 50px;
        border: none;
      }

      .form-group.no-margin-bottom {
        margin-bottom: 0;
      }
    }
  }

  .main-content {
    h2 {
      margin-top: 20px;
      margin-bottom: 20px;

      img {
        margin-left: 20px;
      }
    }
  }

  .last-offers {
    padding-top: $separator-space-top;
    padding-bottom: $separator-space-bottom;
    background-color: $body-bg-dark;
    color: #FFF;

    h2 {
      padding-top: 10px;
      padding-bottom: 10px;
      text-transform: uppercase;
      text-align: center;
      font-weight: lighter;
    }

    .carousel-indicators {
      position: static;
      margin: 0 auto 30px auto;
    }

    .carousel-indicators {
      li {
        border: 1px solid #D6DBDD;
        background-color: #D6DBDD;
        position: relative;
        margin-left: 5px;

        &:first-child {
          margin-left: 0;
        }

        &.active {
          background-color: #97BE0D;
          border: 1px solid #97BE0D;
        }
      }

      li:first-child:before, li:last-child:after {
        display: block;
        position: absolute;
        content: "";
        width: 180px;
        height: 1px;
        background: $border-custom-color;
        top: 4px;
      }

      li:last-child:after {
        right: -210px;
      }

      li:first-child:before {
        left: -210px;
      }
    }
  }
}

.page-login {
  .discover-multiao {
    color: #FFF;

    p {
      font-weight: lighter;
      font-size: 16px;
    }

    border-bottom: 1px solid #FFF;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .wysiwyg {
      width: 80%;
      padding-bottom: 40px;

      @media only screen and (min-width: $screen-md) {
        padding-bottom: 50px;
      }

      @media only screen and (min-width: $screen-lg) {
        padding-bottom: 70px;
      }
    }
  }
}