.alert {
  font-size: 16px;

  i[class^="icon-"] {
    margin-right: 5px;
  }
}

.alert.alert-info {
  .close {
    color: #FFF;
    text-shadow: 0 1px 0 #000;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  > [class^="icon-"] {
    position: relative;
    top: 4px;
    margin-right: 5px;
    font-size: 21px;

    &.text-danger {
      color: #EC3F1D;
    }
  }
}