.ribbon {
  position: relative;
  padding: 20px 30px;
  background-color: #996699;
  color: #FFF;
  font-size: 18px;
  font-weight: lighter;

  &.ribbon-3d {
    margin-left: -10px;
    margin-top: -20px;

    :after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 0;
      border-color: transparent #334A56 transparent transparent;
    }
  }

  .ribbon-important {
    font-weight: bold;
    font-size: 28px;
  }
}