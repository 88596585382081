.form-control {
  font-size: 14px;

  @include placeholder-additionnals();
}

.form-inline {
  .form-group {
    margin-left: 8px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  .checkbox {
    margin-right: 10px;
  }
}

fieldset,
.fieldset{
  background-color: $grey-bg;
  border-radius: $border-radius-base;
  padding: 20px;
  margin-bottom: 30px;

  h2 {
    margin-top: 0;
    margin-bottom: 30px;
  }
}



.fieldset {
  fieldset {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0px;
  }
}

.form-control.tiny {
  @media only screen and (min-width: $screen-sm) {
    width: 99px;
  }
}
.placeholder {
  color: $input-color-placeholder;
  font-style: italic;
}

.help-block {
  color: #a0a8ad;
  font-style: italic;
}

textarea {
  resize: vertical;
}

.back-office label {
  font-size: 20px;
}
.form-group {
  position: relative;

  .control-label-span {
    label {
      display: inline;
    }
  }

  &.has-error {
    .control-label-span {
      color: $brand-danger;
    }
  }
}

.form-group.required {
  position: relative;

  &:before {
    color: $input-color-placeholder;
    content: "*";
    position: absolute;
    top: 0;
    right: -8px;
    font-size: 14px;
  }
}

.checkbox {
  label {
    input[type="checkbox"] {
      display: none;
    }

    position: relative;
    padding-left: 0;
    @include user-select(none);

    .checkbox-clone {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 27px;
      height: 27px;
      padding: 1px 5px;
      margin-right: 5px;
      font-size: 15px;
      border: 1px solid $border-custom-color;
      border-radius: $border-radius-base;
      background-color: #f1f1f1;
      color: $brand-success;
      overflow: hidden;
      text-indent: -999px;

      [class^="icon-"]:before {
        font-weight: bolder;
      }
    }

    input:checked ~ .checkbox-clone {
      text-indent: 0;
    }
  }
  &.has-error {
    .checkbox-clone {
      border-color: $brand-danger;
    }
  }
}