.nav.nav-pills {
  &.primary {
    margin-top: 30px;
    margin-bottom: 30px;
    li {
      width: 25%;
      padding: 0 5px;

      a {
        font-size: 14px;
        color: #adb3b7;
        border: 3px solid #c2c9cc;
        padding-right: 68px;
        text-transform: uppercase;
        @include clearfix();

        .badge {
          position: absolute;
          right: 6px;
          top: 50%;
          margin-top: -14px;
          font-size: 16px;
          font-weight: normal;
          background-color: #b9bfc3;
          padding: 6px 7px;
          border-radius: 50%;
        }
      }

      &.active {
        a {
          background-color: transparent;
          color: $text-color;
          border-color: $text-color;

          .badge {
            color: #FFF;
            background-color: $brand-success;
          }
        }
      }
    }

    li + li {
      margin-left: 0;
    }


    // 2016-04-15 : add RWD support
    @media screen and (max-width: $screen-sm) {
      li {
        float: none;
        width: auto;
        margin: 10px 0;
      }
    }
  }
}

.nav.nav-secondary {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;

  li {
    display: inline-block;
    border-bottom: 2px solid #b9bfc3;
    margin-left: 10px;
    line-height: 30px;

    a {
      color: $text-color;
      font-size: 14px;
      padding: 5px 20px;

      [class^="icon-"] {
        color: #bac0c4;
        margin-right: 5px;
        font-size: 20px;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &.active {
      border-bottom-color: $brand-success;
    }
  }
}