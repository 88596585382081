.radio-buttons-group {
  .control-label {
    font-size: 16px;
    line-height: 48px;
    font-weight: normal;
  }
}

.radio-buttons {
  .radio-button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.has-error {
  .radio-button label, .radio-button input[type="radio"]:checked ~ label {
    border-color: $brand-danger;
  }
}

.help-fee {
  display: none;

  &.active {
    color: $brand-danger;
    border-color: $brand-danger;
    display: block;
  }
}


.radio-button {
  display: inline-block;
  overflow: hidden;

  input[type="radio"] {
    position: absolute;
    left: -200%;
    visibility: hidden;

    &:checked ~ label {
      background-color: $btn-success-bg;
      border-color: $btn-success-bg;
    }
  }

  label {
    border: 1px solid $border-custom-color;
    border-radius: $border-radius-base;
    padding: 10px 20px;
    background-color: #FFF;
    cursor: pointer;
    color: $text-color;

    &[for="indra_ao_fee_1"] {
      padding: 5px 27.51px
    }

    @include user-select(none);
  }

  &.tiny {
    label {
      padding: 4px 20px;
    }
  }

  &.small {

    label {
      font-size: $font-size-base * 0.75;
      padding: 5px 12px;
    }
  }

  &.large {
    label {
      font-size: 20px;
      text-transform: uppercase;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}