/* For Firefox */
input[type='number'] {
  -moz-appearance:textfield;
}/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#cgu-acceptation-text{
  vertical-align: top;
}

#cgu-acceptation{
  vertical-align: top;
  height: 50px;
  margin-bottom: 15px;
}

#indra_newid_cgu_acceptance{
  vertical-align: top;
  margin-top: 0px;
}
#error-acceptation-cgu>p{
  color: #cd2200;
}