$roboto-font-path: '.' !default;

@font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/regular/Roboto-Regular.eot');
    src: url('#{$roboto-font-path}/regular/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
         url('#{$roboto-font-path}/regular/Roboto-Regular.woff') format('woff'),
         url('#{$roboto-font-path}/regular/Roboto-Regular.ttf') format('truetype'),
         url('#{$roboto-font-path}/regular/Roboto-Regular.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/bold/Roboto-Bold.eot');
    src: url('#{$roboto-font-path}/bold/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
         url('#{$roboto-font-path}/bold/Roboto-Bold.woff') format('woff'),
         url('#{$roboto-font-path}/bold/Roboto-Bold.ttf') format('truetype'),
         url('#{$roboto-font-path}/bold/Roboto-Bold.svg#robotobold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/italic/Roboto-Italic.eot');
    src: url('#{$roboto-font-path}/italic/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
         url('#{$roboto-font-path}/italic/Roboto-Italic.woff') format('woff'),
         url('#{$roboto-font-path}/italic/Roboto-Italic.ttf') format('truetype'),
         url('#{$roboto-font-path}/italic/Roboto-Italic.svg#robotoitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$roboto-font-path}/bolditalic/Roboto-BoldItalic.eot');
    src: url('#{$roboto-font-path}/bolditalic/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('#{$roboto-font-path}/bolditalic/Roboto-BoldItalic.woff') format('woff'),
         url('#{$roboto-font-path}/bolditalic/Roboto-BoldItalic.ttf') format('truetype'),
         url('#{$roboto-font-path}/bolditalic/Roboto-BoldItalic.svg#robotobold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$roboto-font-path}/light/Roboto-Light.eot');
  src: url('#{$roboto-font-path}/light/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('#{$roboto-font-path}/light/Roboto-Light.woff') format('woff'),
  url('#{$roboto-font-path}/light/Roboto-Light.ttf') format('truetype'),
  url('#{$roboto-font-path}/light/Roboto-Light.svg#robotolight') format('svg');
  font-weight: 300;
  font-style: normal;
}