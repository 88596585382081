#indra_dashboard_firstRegistrationDateStart , #indra_dashboard_firstRegistrationDateEnd {
    min-width: 104px;
}

.filter-date-ao {
    h3 {
        width: 240px;
        display: inline-block;
        vertical-align: middle;
        margin:0;
    }

    .mt-1 {
        margin-top: 10px;
    }

    .mb-1 {
        margin-bottom: 10px;
    }
}

.section-cover {
    .section {
        .form-group {
            .icon-cart-3 {
                vertical-align: bottom;
                margin-left: 10px;
            }

            .export-purchaser {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
            }
        }
        .export-information {
            margin-bottom: 20px;
        }
    }
    .fieldset {
        margin-bottom: 10px;
    }
}

.access-table-id-link {
    tbody {
        tr {
            transition: all .25s ease;

            &:hover {
                background: $brand-success;
                cursor: pointer;
            }
        }
    }
}
