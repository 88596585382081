.section-cover {
  .section-title {
    color: #FFFFFF;
    background-color: $body-bg-dark;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    cursor: pointer;

    h2, .h2 {
      background: transparent url(/assets/img/dropdown.svg) no-repeat right 15px center;
      background-size: 17px 9px;
      margin-top: 0;
      margin-bottom: 0;
      text-transform: uppercase;
    }


    &.dropup {
      h2, .h2 {
        background: transparent url(/assets/img/dropdown.svg) no-repeat right 15px center;
      }
    }
  }
}

.faqblock {
  background: #eaedee;
  padding: 15px 0 30px;
  border-radius: 4px;
  margin-top: 80px;

  svg {
    fill: $text-color;
    position: relative;
    margin-bottom: -33px;
    top: -40px;
    background: white;
    border-radius: 50%;
  }

  .ftitle {
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 20px;
  }

  .txtinfo {
    max-width: 390px;
    font-size: 1.7rem;
    display: inline-block;
  }

  div {
    margin-top: 20px;

    a {
      background: white;
      font-size: 1.5rem;
      padding: 5px 10px;
      border: 1px solid $text-color;
      color: $text-color;
      text-decoration: none !important;

      svg {
        width: 20px;
        height: 20px;
        position: relative;
        top: 4px;
        left: 0;
        margin: 0 0 0 6px;
        transition: all .25s ease;
      }

      &:hover, &:focus {
        svg {
          left: 4px;
        }
      }
    }
  }
}
