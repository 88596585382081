html, body {
  height: 100%;
  position: relative;
}

.ahidden {
  @include hidden-accessibility();
}

.land {
  position: relative;
  padding-top: $separator-space-top;
  padding-bottom: $separator-space-bottom;

  .container {
    position: relative;
  }
}

.light,
h1.light, .h1.light,
h2.light, .h2.light,
h3.light, .h3.light,
h4.light, .h4.light,
h5.light, .h5.light,
h6.light, .h6.light {
  font-weight: lighter;
}

.regular {
  font-weight: normal;
}

p:empty {
  margin-bottom: 0;
  height: 10px;
}

.ibox {
  display: inline-block;
  vertical-align: middle;
}

#top-link-block.affix-top {
  position: absolute; /* allows it to "slide" up into view */
  bottom: -82px; /* negative of the offset - height of link element */
  right: 10px; /* padding from the left side of the window */
}
#top-link-block.affix {
  position: fixed; /* keeps it on the bottom once in view */
  bottom: 18px; /* height of link element */
  right: 10px; /* padding from the left side of the window */
}

.label_alert{
  padding-top: 2%;
}

.mt40 {
  margin-top: 40px;
}

.bgreen {
  color: $brand-success;
}

.tred {
  color: $strong-red;
}

.perror {
  &>div {
    margin-top: 15px;
    color: white;
    background: $strong-red;
    display: inline-block;
    font-size: 13px;
    padding: 6px 12px;
    border-radius: 4px;
  }
}

.btn-gray {
  color: #333;
  background-color: $grey-bg;
  border-color: $grey-bg;

  &:hover, &:focus {
    color: #333 !important;
    background-color: darken($grey-bg, 15%);
    border-color: darken($grey-bg, 15%);
  }
}

.hidethis {
  display: none;
}
