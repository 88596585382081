.detail-actions {
  position: absolute;
  top: 25px;
  right: 0;
  padding-left: 0;
  display: none;

  @media screen and (min-width: $screen-sm) {
    display: block;
  }

  li {
    position: relative;
    display: inline-block;
    padding-left: 50px;
    margin-right: 10px;
    color: $border-custom-color;
    height: 42px;

    a {
      color: $text-color;

      &:hover, &:hover:active {
        text-decoration: none;
      }
    }
  }

  [class^="icon-"] {
    padding: 5px 10px;
    background-color: $brand-success;
    border-radius: 50%;
    position: absolute;
    left: 0;
    font-size: 20px;
    color: #FFF;

    &:before {
      line-height: 30px;
    }
  }

  .detail-action-table {
    display: table;
    width: 60px;
    height: 100%;
  }

  .detail-action-cell {
    display: table-cell;
    vertical-align: middle;
  }
}